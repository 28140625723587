import React from 'react';

import ContactSection from '../../Components/ContactSection/contactSection'

export default function contact() {

 

  return (
    <>
      
      <div >
                <ContactSection />
              
            </div>
    </>
  )
}
