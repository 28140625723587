import '../../../Components/HomeGalleryy/homeGalleryy.css'
import '../../../Components/HomeGalleryy/homeGalleryy.scss'

import React, { useState, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { deleteCategory, getCategories } from '../../../services/images';
import { useEffect } from 'react';

import { setPagesSession } from '../../../Components/general-functions/helpers';

import galleryImg10 from '../../../Images/art/galleryImg54.jpg'
import { API_BASE_URL } from '../../../constants';

const ImageCategoriesDashboard = props=>{



    const navigate = useNavigate();
    const [clickCounts, setClickCounts] = useState(1);// we use it to detect double clicks, since event.detail doesn't work on phones.
    const [lastCategory, setLastCategory] = useState("");
    
    const [categories, setCategories] = useState([]);
    const [currentCategoryImages, setCurrentCategoryImages] = useState(null);

    // const sport = [galleryImg7, galleryImg65, galleryImg66,galleryImg67, galleryImg68, galleryImg69, galleryImg70, galleryImg71, galleryImg72, galleryImg73, galleryImg74, galleryImg75, galleryImg76, galleryImg77, galleryImg78, galleryImg79, ];
    // const [currentSport, setCurrentSport] = useState(0);

    // const medical = [galleryImg16, bloodDonationActivity, medicalCheckUpActivity];
    // const [currentMedical, setCurrentMedical] = useState(0);

    // const art = [galleryImg54, galleryImg55, galleryImg56, galleryImg57, galleryImg58, galleryImg62, galleryImg63, culturalInviteActivity];
    // const [currentArt, setCurrentArt] = useState(0);

    // const iftarParty = [galleryImg9, galleryImg43, galleryImg81, galleryImg85,];
    // const [currentIftarParty, setCurrentIftarParty] = useState(0);

    // const culturals = [galleryImg1, galleryImg17, galleryImg38, galleryImg47, galleryImg50, galleryImg86];
    // const [currentCulturals, setCurrentCulturals] = useState(0);

    // const seminar = [galleryImg52, galleryImg80, galleryImg82, ];
    // const [currentSeminars, setCurrentSeminars] = useState(0);

    // const awards = [galleryImg19, galleryImg21, galleryImg23, galleryImg25, galleryImg26, galleryImg27, galleryImg28, galleryImg33];
    // const [currentAwards, setCurrentAwards] = useState(0);

    // const careerGuidance = [galleryImg11, galleryImg87, galleryImg88, galleryImg89, seminarsActivity];
    // const [currentCareerGuidance, setCurrentCareerGuidance] = useState(0);

    const screen1100 = window.visualViewport.width <= 1100 && window.visualViewport.width > 550;
    const screen550 = window.visualViewport.width<=550;
    

    useEffect(()=>{
       
        getCategories().then(response =>{
            if(response.status == 200){
                setCategories(response.data.data);
            }
            else{
                // alert("couldn't load images");
            }
        }).catch(err=>{
            // alert("couldn't load images");
            console.error(err);
        })

       
        
    },[])

    useEffect(()=>{

        if(categories.length > 0){
            console.log("currentCategoryImagesClone: ", currentCategoryImages);
            const intervalId = setInterval(() => {
                var currentCategoryImagesClone = {}
                categories.forEach(c=>{
                    currentCategoryImagesClone[c.name] = Math.floor(Math.random() * c.images.length);
                })
                console.log("interval: currentCategoryImagesClone: ", currentCategoryImagesClone);
                setCurrentCategoryImages(currentCategoryImagesClone);
            }, 5000);

            return () => clearInterval(intervalId);

        }
    },[categories])

   
    const imageStyle = {
        margin: 6,
        display: 'flex',
        cursor: 'pointer',
        width: 250,
        height: 200,
        borderRadius: 5,
    };


    const onLoadMore = ()=>{
        var pages =  window.sessionStorage.getItem('pages');
        
        pages = JSON.parse(pages);
        pages = {...pages, galleryimages:{category: "general"}};
        window.sessionStorage.setItem('pages', JSON.stringify(pages));
            
        navigate('/gallery/images');
    }

    const navigateToViewImages = (category)=>{
        if(screen550 || screen1100){
            console.log("lastCategory: ", lastCategory);
            setLastCategory(category)

            if(category == lastCategory){
               var pages =  JSON.parse(window.sessionStorage.getItem('pages'));
               pages = {...pages, galleryimages:{category: category}};
                window.sessionStorage.setItem('pages', JSON.stringify(pages));
                
                navigate('/dashboard/gallery/images');
            }
        }   
        else{
            var pages =  window.sessionStorage.getItem('pages');
        
            pages = JSON.parse(pages);
            pages = {...pages, galleryimages:{category: category}};
            window.sessionStorage.setItem('pages', JSON.stringify(pages));
                
            navigate('/dashboard/gallery/images');
            
          
        }
        
    }


    function handleDeleteCategory(id){
        var yes = window.confirm("Are you sure that you want to delete the category with all its images?");
        
        if(yes){
            deleteCategory(id).then(response=>{
                alert(response.data.message);
                var categoriesClone = categories.filter(c =>c.id != id);
                setCategories(categoriesClone);
            }).catch(err=>{
                alert("Some error happened");
                console.log(err);
            })
        }       
       
    }

    function onAddMore(){
        navigate('/dashboard/gallery/categories/add');
    }

    function Screen550Categories(){
        var categories550 = [];
        for(var i = 0 ; i < categories.length; i++){
            var item = [categories[i]];
            if(categories[i+1] != undefined){
                item.push(categories[i+1]);
                i++;
            }
            categories550.push(item);
        }
        
        return(
        <div className="row" style={{width:"78%", marginLeft:"11%", marginRight:"11%", marginBottom:"5%",  padding:"0", marginTop:"3%" }}>
            { 
            categories550.map(item=>{
                return (
                    <div className='row' style={{height:(100*window.visualViewport.width/550)+'px', width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
                        <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
                            <img className='image' src={API_BASE_URL+'/images/'+ item[0].name +'/'+item[0].images[(currentCategoryImages == null)? 0 : currentCategoryImages[item[0].name]].path} alt="First slide"/>
                            <div class="middle">
                                <div onClick={()=>navigateToViewImages(item[0].name)} class="text">{item[0].name}</div>
                            </div>
                            <div class="delete-button">
                                <div onClick={()=>handleDeleteCategory(item[0].id)}  class="delete-text">X</div>
                            </div>
                        </div>
                        {
                            (item[1] != undefined)?
                                <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
                                    <img className='image' src={API_BASE_URL+'/images/'+ item[1].name +'/'+ item[1].images[(currentCategoryImages == null)? 0 :currentCategoryImages[item[1].name]].path} alt="First slide"/>
                                    <div class="middle">
                                        <div onClick={()=>navigateToViewImages(item[1].name)} class="text">{item[1].name}</div>
                                    </div>

                                    <div class="delete-button">
                                        <div onClick={()=>handleDeleteCategory(item[1].id)} class="delete-text">X</div>
                                    </div>
                                </div>
                            :
                                <></>
                        }
                    </div>
                )
            })
                
            }

            <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"3%",}}>         
                <div className="gallery-btn-txt" to="/about"  style={{paddingLeft:"5%", paddingRight:"5%"}}>
                    <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>{/*className="btn about-home-btn*/}
                        Load <span>more</span>
                    </button>
                </div>
            </div>

            <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"1%", marginRight:"0",}}>
                <div className="gallery-btn-txt" to="/about"  style={{width:"100%", padding:"0"}}>
                    <button type="button" className="btn gallery-btn" onClick={()=>onAddMore()}>{/*className="btn about-home-btn*/}
                        Add <span className='title-design'>more</span>
                    </button>
                </div>
            </div>
        </div>
        )
    }


    function Screen1100Categories(){

        var categories1100 = [];
        for(var i = 0 ; i < categories.length; i++){
            var item = [categories[i]];
            if(categories[i+1] != undefined){
                item.push(categories[i+1]);
                i++;
            }
            categories1100.push(item);
        }

        
        return (
            
            <div className="row" style={{width:"78%", marginLeft:"11%", marginRight:"11%", marginBottom:"5%",  padding:"0", marginTop:"3%" }}>
               {
                categories1100.map(item=>{
                    return(
                        <div className='row' style={{height:"200px",width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
                            <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
                                <img className='image' src={API_BASE_URL+'/images/'+ item[0].name +'/'+item[0].images[(currentCategoryImages==null)? 0 : currentCategoryImages[item[0].name]].path} alt="First slide"/>
                                <div class="middle">
                                    <div onClick={()=>navigateToViewImages(item[0].name)} class="text">{item[0].name}</div>
                                </div>

                                <div class="delete-button">
                                    <div onClick={()=>handleDeleteCategory(item[0].id)} class="delete-text">X</div>
                                </div>
                            </div>
                            {
                                (item[1] != undefined)?
                                    <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
                                        <img className='image' src={API_BASE_URL+'/images/'+ item[1].name +'/'+ item[1].images[(currentCategoryImages==null)? 0 : currentCategoryImages[item[1].name]].path} alt="First slide"/>
                                        <div class="middle">
                                            <div onClick={()=>navigateToViewImages(item[1].name)} class="text">{item[1].name}</div>
                                        </div>

                                        <div class="delete-button">
                                            <div onClick={()=>handleDeleteCategory(item[1].id)} class="delete-text">X</div>
                                        </div>
                                    </div>
                                :
                                    <></>
                            }
                        </div>
                    )
                })
               }
                <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"3%",}}>         
                    <div className="gallery-btn-txt" to="/about"  style={{paddingLeft:"5%", paddingRight:"5%"}}>
                        <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>{/*className="btn about-home-btn*/}
                            Load <span>more</span>
                        </button>
                    </div>
                </div>
               
               <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"1%", marginRight:"0",}}>
                <div className="gallery-btn-txt" to="/about"  style={{width:"100%", padding:"0"}}>
                    <button type="button" className="btn gallery-btn" onClick={()=>onAddMore()}>{/*className="btn about-home-btn*/}
                        Add <span className='title-design'>more</span>
                    </button>
                </div>
                </div>
                
            </div>

          
            
        )
    }




    function WideScreenCategories(){

        var categoriesWideScreen = [];
        for(var i = 0 ; i < categories.length; i++){
            var item = [categories[i]];
            for(var j = 1 ; j <=3; j++){
                if(categories[i+1] != undefined){
                    item.push(categories[i+1]);
                    i++;
                }
                else{
                    break;
                }
            }

            categoriesWideScreen.push(item);
        }
       
    
        return(
            <div className="col-sm-1" style={{width:"63%", marginLeft:"1%", marginRight:"1%" }}>
            {
                categoriesWideScreen.map(item=>{
                    return(
                        <div className='row' style={{height:"190px",width:"100%", marginBottom:"20px" }}>
                            {
                                item.map(c=>{
                                    return(
                                    //    (c.images.length>0)? is images got empty, an error will be raised
                                        <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
                                            <img className='image' src={API_BASE_URL+'/images/'+ c.name +'/'+ c.images[(currentCategoryImages==null)? 0 : currentCategoryImages[c.name] ].path} alt="First slide"/>
                                            <div class="middle">
                                                <div onClick={()=>navigateToViewImages(c.name)} class="text">{c.name}</div>
                                            </div>

                                            <div class="delete-button">
                                                <div onClick={()=>handleDeleteCategory(c.id)} class="delete-text">X</div>
                                            </div>
                                        </div>
                                    //    :<></>
                                        
                                    )
                                })
                            }      
                    </div>
                    )
                })
               
            }

            <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"1%", marginRight:"0",}}>
                <div className="gallery-btn-txt" to="/about"  style={{width:"100%", padding:"0"}}>
                    <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>{/*className="btn about-home-btn*/}
                        Load <span className='title-design'>more</span>
                    </button>
                </div>
            </div>

            <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"1%", marginRight:"0",}}>
                <div className="gallery-btn-txt" to="/about"  style={{width:"100%", padding:"0"}}>
                    <button type="button" className="btn gallery-btn" onClick={()=>onAddMore()}>{/*className="btn about-home-btn*/}
                        Add <span className='title-design'>more</span>
                    </button>
                </div>
            </div>



        </div>

            
        )
    }












    return (
        
        
        (screen550)?
      

        <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
        <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
            <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
                <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
            </div>
        </div>

        <div className="row" style={{width:"100%", marginBottom:"5%", padding:"0", marginLeft:"0", marginRight:"0"}}>
            
            <div className="row" style={{width:"78%", height: (200*window.visualViewport.width/550)+'px', marginLeft:"11%", marginRight:"11%", padding:"0",}}>
                {/* The Big Image */}
                <img style={{width:"100%", height:"100%", margin:"0", borderRadius: "4%"}}
                    
                    src={galleryImg10}
                    alt="First slide"
                />
                    
            </div>
           
            <Screen550Categories />

        </div>
    </div>






        :


        (screen1100)?

        <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
            <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
                <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
                    <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
                </div>
            </div>

            <div className="row" style={{width:"100%", marginBottom:"5%", padding:"0", marginLeft:"0", marginRight:"0"}}>
                
                <div className="row" style={{width:"78%", height:"400px", marginLeft:"11%", marginRight:"11%", padding:"0",}}>
                    {/* The Big Image */}
                    <img style={{width:"100%", height:"100%", margin:"0", borderRadius: "4%"}}
                        
                        src={galleryImg10}
                        alt="First slide"
                    />
                    
                        
                </div>

                <Screen1100Categories />
            </div>
        </div>




        :







        <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
        <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
            <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
                <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
            </div>
        </div>

        <div className="row" style={{width:"100%", height:"70%", marginBottom:"5%", padding:"0"}}>
            
            <div className="col-sm-1" style={{width:"30%", height:"400px", marginLeft:"2%", marginRight:"3%", padding:"0",}}>
                {/* The Big Image */}
                    <img style={{width:"100%", height:"100%", margin:"0", padding:"0", borderRadius: "3%"}}
                       
                       src={galleryImg10}
                       alt="First slide"
                    />

            </div>

            <WideScreenCategories />

        </div>
    </div>


    )

}


    


export default ImageCategoriesDashboard;