
import React from "react";
import './contactSection.css'


import { useEffect } from 'react';
import { setPagesSession } from '../../Components/general-functions/helpers';
import { contact } from "../../services/contact";

function ContactSection() {
  useEffect(()=>{
    setPagesSession("contact");//this will remove all other pages values from the key "pages" (which is a stringified json object) of our session storage.
  },[])
  

  function handleContact(){
    var formData = new FormData();

    formData.append('name', document.getElementById('name').value);
    formData.append('email', document.getElementById('email').value);
    formData.append('phone', document.getElementById('phone').value);
    formData.append('message', document.getElementById('message').value);

    contact(formData).then(response =>{
      alert(response.data.message);
    }).catch(err=>{
      alert("Couldn't send the message.");
    })

  }
  
    return (
        <>
        <div className='container pt-2 pb-5'>
        <div className='form-header pt-5 pb-5 text-center'>
          <h3 className='form-title'>
          MESSAGE US
          </h3>
          
        </div>
        <div className='form-content'>
          <div className='row'>
              <div className="col-md-2 col-lg-2"></div>
            <div className='col-md-8 col-lg-8 form-section'>
              <form>
                <div className='form-group'>
                  <input
                    type='text'
                    id="name"
                    name="name"
                    className='form-control rounded-1'
                    aria-describedby='emailHelp'
                    placeholder='Enter Name...'
                    required
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='email'
                    id="email"
                    name="email"
                    className='form-control rounded-1'
                    aria-describedby='emailHelp'
                    placeholder='Enter email...'
                    required
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='number'
                    name="phone"
                    id="phone"
                    className='form-control rounded-1'
                    aria-describedby='emailHelp'
                    placeholder='Enter contact number...'
                    required
                  />
                </div>
                <div className='form-group'>
                  <textarea
                  id="message"
                  name="message"
                    className='form-control rounded-1'
                    rows='5'
                    placeholder='Enter Message...'
                  />
                </div>
                <div className='form-group text-center'>
                  <button type="submit" onClick={(e)=>{e.preventDefault(); handleContact();}} className='btn btn-hover-contact'>
                    Send Message
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-2 col-lg-2"></div>
          </div>
        </div>
      </div>
        </>
    )
}


export  default ContactSection