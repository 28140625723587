import { useState } from "react";
import { Classnames } from "react-alice-carousel";
import { API_BASE_URL } from "../../../constants";
import { addCategory } from "../../../services/images"





export default function AddImageCategory(props){

    const [imageFiles, setImageFiles] = useState(null);

    const onSubmit = ()=>{
        var data = new FormData()
        // var formData = new FormData(document.querySelector('form'))
        data.append('name', document.getElementById('categoryName').value);
        
        var keys = Object.getOwnPropertyNames(imageFiles);
        keys.forEach(k=> {
            data.append('file'+k, imageFiles[k]);
        });

        console.log(imageFiles);
        addCategory(data).then(response => {
            alert(response.data.message);
        }).catch(err=>{
            alert("Couldn't add the category");
            console.error(err);
        })
    }

    return (
        <div className="container-fluid" style={{width:"100%", marginBottom:"1%", marginTop:"3%", padding:"0",}}>
            <form style={{marginLeft:"1%"}}>
            
            <div class="form-group">
                <label for="categoryName"  style={{marginBottom:"1%"}} >Category Name</label>
                <input type="text" class="form-control" id="categoryName" name="name" placeholder="Category Name" />
            </div>
            <div class="form-group">
                <label for="imageFiles" style={{marginBottom:"1%"}}>Upload Images: </label>
                <input style={{width:"100%"}} type="file" id="imageFiles" name="imageFiles" onChange={(e)=>{setImageFiles(e.target.files)}} multiple/>
            </div>
           

            <button type="button" onClick={(e)=>{ e.preventDefault();onSubmit()}} className="btn btn-primary">Submit</button>

            </form>
        </div>
    )
}