import Carousel from 'react-bootstrap/Carousel'
import React from 'react'
import Welcomeeee from '../../Images/Welcomeeee.jpg'
import Lakeee from '../../Images/Lakeee.jpg'
import Desert from '../../Images/Desert.jpg'
import village from '../../Images/village.jpg'
import './heroSection.css'
import { useEffect } from 'react'
import { getImageSlides } from '../../services/imageslides'
import { useState } from 'react'
import { API_BASE_URL, IMAGES_URL } from '../../constants'



export default function HeroSection({setLoading}) {

    const [imageSlides, setImageSlides] = useState([]);
    const screen1000 = window.visualViewport.width <=1000;

    useEffect(()=>{
        getImageSlides().then(response=>{
            setImageSlides(response.data.data);
            if(response.data.data.length == 0) setLoading(false);
        }).catch(err=>{
            console.error(err);
            // alert("Couldn't load image slides");
            setLoading(false);
        })
    },[])

    return (
        (screen1000)?
        
        <div style={{width:"100%", height:"100%", margin:"0", padding:"0"}}>

            <Carousel style={{width:"100%", height:"100%", margin:"0", padding:"0"}} className='hero-carousel' fade>
                {
                    imageSlides.map((slide, index)=>{
                        return(
                            <Carousel.Item key={index} interval={5000}>
                            <img
                                className="d-block w-100 "
                                src={IMAGES_URL+'/'+slide.image.path}
                                alt="First slide"
                                onLoad={()=>{setLoading(false); console.log("loaded")}}
                                style={{width:"100vw", height:"30vh"}}
                            />
        
                      
                            <Carousel.Caption>
                            <h1 className='carousel-text' style={{wordWrap:"break-word"}}> <strong> {slide.caption} </strong> </h1>
                                
                            </Carousel.Caption>
                           
                        </Carousel.Item >
                        )
                    })
                }
               

                


            </Carousel>

        </div>
        :

        <div style={{width:"100%", height:"100%", margin:"0", padding:"0"}}>

            <Carousel style={{width:"100%", height:"100%", margin:"0", padding:"0"}} className='hero-carousel' fade>
                {
                    imageSlides.map((slide, index)=>{
                        return(
                            <Carousel.Item key={index} interval={5000}>
                            <img
                                className="d-block w-100 "
                                src={IMAGES_URL+'/'+slide.image.path}
                                // alt="First slide"
                                onLoad={()=>{setLoading(false); console.log("loaded")}}
                                style={{width:"100vw", height:"82.1vh"}}
                            />
        
                      
                            <Carousel.Caption>
                            <h1 className='carousel-text' style={{wordWrap:"break-word"}}> <strong> {slide.caption} </strong> </h1>
                                
                            </Carousel.Caption>
                           
                        </Carousel.Item >
                        )
                    })
                }
               

                


            </Carousel>

        </div>
    )
}



// {/* <Carousel.Item interval={5000}>
//                     <img
//                         className="d-block w-100"
//                         src={Lakeee}
//                         alt="Second slide"
//                     />

//                     <Carousel.Caption>
//                      { /*  <h1 className='carousel-text'>Expanding human flourishing by building a stronger and healthier community since 1969</h1> */}

//                      </Carousel.Caption>
//                      </Carousel.Item>
//                      <Carousel.Item interval={3000}>
//                          <img
//                              className="d-block w-100"
//                              src={Desert}
//                              alt="Third slide"
//                          />
     
//                          <Carousel.Caption className='Carousel-Caption3'>
//                              {/* <h1 className='carousel-title'> <strong>Stand by you…</strong> </h1>
//                               <h1 className='carousel-text3'>
//                                 Everyone is finer when we have someone there to pay attention, we know we are being seen and heard, and we will be there to do that for another person. We need to reside in a community that literally values everybody having someone to turn to… With this precept,
//                                  Abu Dhabi Malayali Samajam has build an exceptional mark in the life of Malayalees in Abu Dhabi.</h1> */}
//                          </Carousel.Caption>
//                      </Carousel.Item>
     
     
//                      <Carousel.Item interval={3000}>
//                          <img
//                              className="d-block w-100"
//                              src={village}
//                              alt="Fourth slide"
//                          />
//                           <Carousel.Caption>
//                          <h1 className='carousel-text'> <strong></strong>  </h1>
                             
//                          </Carousel.Caption>
     
     
//                      </Carousel.Item> */}