import './whatssApp.css'

export default function WhatssApp() {
    return (
        <>
            <div>
                <a class="whats-app" href="https://wa.me/<put the number here without braces>" target="_blank">
                    <i class="fa fa-whatsapp my-float"></i>
                </a>
            </div>

        </>
    )
}