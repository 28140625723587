import { useState } from "react";
import { useEffect } from "react";
import { API_BASE_URL } from "../../../constants";
import {deleteEvent, editEvent, getEvent } from "../../../services/events";
import { useNavigate } from "react-router";






export default function EditEvent(){
    const eventId = window.sessionStorage.getItem('event');

    const [currentEvent, setCurrentEvent] = useState(null);
    const [newImageFile, setNewImageFile] = useState('');
    const navigate = useNavigate();

    useEffect(()=>{
        if(!Boolean(eventId)){
            navigate('/dashboard/activities');
        }
        
        getEvent(eventId).then(response=>{
            document.getElementById('title').value = response.data.data.title;
            document.getElementById('date').value = response.data.data.date;
            document.getElementById('currentImage').src = API_BASE_URL+'/images/'+response.data.data.image.path;
            

        })
    },[])

    function handleEditEvent(){
        var formData = new FormData();
        formData.append('title', document.getElementById('title').value);
        formData.append('date', document.getElementById('date').value);
        formData.append('image', newImageFile[0]);
        formData.append('_method', 'put');

        editEvent(eventId, formData).then(response =>{
            if(response.data.data.image !== undefined) document.getElementById('currentImage').src = API_BASE_URL+'/images/'+response.data.data.image.path;
            alert('Event is updated successfully');
        }).catch(err=>{
            console.log(err);
            alert("Couldn't update the event");
        })
    }

    function handleDeleteEvent(){
        if(window.confirm("Are you sure you want to delete this event?")){
            deleteEvent(eventId).then(response =>{
                alert(response.data.message);
                navigate('/dashboard/events');
            }).catch(err=>{
                console.log(err);
                alert("Couldn't delete the event.");
            })
        }
       
    }

    return (
        <div className="container-fluid" style={{marginTop:0, padding:0, backgroundColor:"#F5F5F5"}}>
            <div className='row text-center' style={{width:"100%", marginRight:"0", marginLeft:"0%", }}>
                <h1 style={{color:"orange", marginTop:"2%"}}>Edit Event</h1>
                
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label for="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label for="date" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Date: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%",  width:"50%"}} type="date" id="date" name="date"></input>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label for="imageFile" style={{width:"100%", marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Current Image: </label>
                            </div>
                            <div className="col">
                                <img id="currentImage" style={{marginTop:"1%", width:"50%"}} src=''/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="form-group">
                            <div className="col">
                                <label for="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>New Image: </label>
                            </div>
                            <div className="col">
                                <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImageFile(e.target.files)}/>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row text-center" style={{marginTop:"1%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}} onClick={handleEditEvent} type="button" className="btn btn-primary">Edit Event</button>

                        </div>
                    </div>
                    <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                        <div className="col text=center">
                            <button style={{width:"25%"}}  onClick={handleDeleteEvent} type="button" className="btn btn-danger">Delete Event</button>
                        </div>
                    </div>

                
        </div>
        </div>
        
    )
}