import { useEffect, useState } from "react";
import { setPagesSession } from "../../Components/general-functions/helpers";
import HeroSection from "../../Components/HeroSection/heroSection";
import HomeAbout from "../../Components/HomeAbout/homeAbout";
import HomeActivities from "../../Components/HomeActivities/homeActivities";
import HomeEvent from "../../Components/HomeEvent/homeEvent";
import HomeFacility from "../../Components/HomeFacility/homeFacility";
import HomeFlash from "../../Components/HomeFlash/homeFlash";
import HomeGallery from "../../Components/HomeGallery/homeGallery";
import HomeGalleryy from "../../Components/HomeGalleryy/homeGalleryy";
import Logo from '../../Images/Logo.jpg'

import '../loadinglogo.css';



export default function Home() {
    useEffect(()=>{
        setPagesSession("home");//this will remove all other pages values from the key "pages" (which is a stringified json object) of our session storage.
      },[])

      const [loading, setLoading] = useState(true);
    

      
      // const [imageSlides, setImageSlides] = useState([]);
      // const screen1000 = window.visualViewport.width <=1000;
  
      // useEffect(()=>{
      //     getImageSlides().then(response=>{
      //         setImageSlides(response.data.data);
      //     }).catch(err=>{
      //         console.error(err);
      //         alert("Couldn't load image slides");
      //     })
      // },[])

    

    return (
      <>
         <div hidden={!loading} className="container-fluid" style={{width:"100vw", height:"100vh",}}>
          <div className="row text-center">
              <div className="col my-auto text-center" style={{width:"100%", height:"100%", }}>
                  

                
                  {/* <img

                      src={Logo}
                    //   style={{width:"200px", height:"200px", borderRadius:"50%" , marginTop:"25vh"}} 
                    className="loading-logo"
                  /> */}
              </div>
          </div>
        </div>
       
        <div hidden={loading}>
       
        <HeroSection setLoading={setLoading}/>
        <HomeFlash />
        <HomeAbout />
        <HomeFacility />
        <HomeGalleryy />
        <HomeEvent /> 
        {/* <HomeActivities/>  */}
        </div>

        </>
    )
}