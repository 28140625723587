import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getEvents = async ()=>{
    const response = await axios.get(API_BASE_URL+'/api/events');
    return response;
}

export const getEvent = async (eventId)=>{
    const response = await axios.get(API_BASE_URL+'/api/events/'+eventId);
    return response;
}


export const addEvent = async (formData)=>{
    const response = await axios.post(API_BASE_URL+'/api/events/add', formData);
    return response;
}

export const deleteEvent = async (eventId) =>{
    const response = await axios.delete(API_BASE_URL+'/api/events/'+eventId+'/delete');
    return response;
}

export const editEvent = async (eventId, formData) =>{
    const response = await axios.post(API_BASE_URL + '/api/events/'+eventId+'/edit', formData);
    return response;
}