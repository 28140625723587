import React, { useState } from 'react'
// import './about.css'
import '../../Components/HomeAbout/homeAbout.css'

import Keralam from '../../Images/Keralam.jpg'
import { setPagesSession } from '../../Components/general-functions/helpers';
import { useEffect } from 'react';
import { getField } from '../../services/general-fields';
import Logo from '../../Images/Logo.jpg'
import '../loadinglogo.css';


export default function About() {

    // useEffect(()=>{
    //     setPagesSession("about");//this will remove all other pages values from the key "pages" (which is a stringified json object) of our session storage.
    // },[])

    // return (
    //     <>
    //         <div className='about-page ' >
    //         <div className="container" >
    //             <div style={{marginTop: "5%"}}>
    //                 <h1 className='about-tittle'>
    //             	    About Us <br/><br/>                    
    //                  </h1>
    //             </div>
    //             <div class=" about-card mb-3">
    //                 <div class="row  g-0">
    //                     <div class="col-lg-6 ">
    //                         <img className='aboutusimg' src={Keralam} alt="" />
    //                     </div>
    //                     <div class="col-lg-6 ">
                            // <div class="card-body">
                            //     <div className='about-head'>
                            //         <h1 className='about-mainn mb-5 mt-4'>
                            //         </h1>
                            //         <p className='about-descriptionn'>
                                    // <p className='about-description mb-3'>Being one of the escalating lands in terms of social welfare, quality of life, verdant, ecological diversity, education, literacy, art & cultural heritage, Kerala is renowned as the God’s own country with its beautiful land called as paradise. </p>
                                    //     <p className='about-description'>We, Abu Dhabi Malayalee Samajam aims to promote and revive the traditions of the great land and maintain a gathering for the Keraliates residing in Abu Dhabi, UAE since 1969 swear the importance of the Kerala community in the country. Abudhabi Malayalee Samajam has inculcated in bringing morals and entice people in promoting the vivid traditions and ethics  from time to time. </p>
                                    //     <p className='about-description'>We always encouraged an embracing attitude towards the people of Kerala and gave out the best possible in fulfilling the mission to zeal the rich culture and heritage along with humanitarian concerns. Food & Medicines are also supplied to the less-privileged people  who are really in need by organizing various fund-raising activities to help people facing crisis during  natural calamities.</p>
                                    //     <p className='about-description'>Organizes several artistic, social events with traditional festivals including the prominent Onam feasts, ifthar parties, camps for children, seminars, conferences,  medical camps & check ups, blood donation camps,  much more.</p>
                                    //     <p className='about-description'>During the pandemic times of Covid -19, we solely carried out worthy humanitarian aids to fight against the illness & crisis . </p>
                                    //     <p className='about-description'>Sheikh Nahyan bin Mubarak Al Nahyan, Minister of Culture and Knowledge Development, inaugurated the new building of Abu Dhabi Malayalee Samajam in Mussafah in 2016. The building comprises a main hall, mini-hall, library & conference room. It also has badminton courts and playing area for kids.</p>
                                        // <p className='about-description'>Addressing the Keralite gathering he said: "Kerala is the most literate and socially-developed state in India. You have made a unique contribution to the peace and prosperity of the UAE."</p></p>
                            //     </div>
                            // </div>
    //                     </div>
    //                 </div>
    //             </div>

    //         </div>
    //         {/* <div className="row">
    //             <div className="col-lg-6 col-md-6 col-12">
    //                 <div className='bg-image'>
    //                 </div>
    //             </div>
    //             <div className="col-lg-6 col-md-6 col-12">
    //             </div>
    //         </div> */}
    //         </div>
    //     </>
    // )


















    const [loading, setLoading] = useState(true);


    const isMobile = visualViewport.width <= 1200 ;
    const [aboutUs, setAboutUs] = useState([]);

    useEffect(()=>{
        window.scrollTo(0,0);

        getField('About Us').then(response=>{
            setLoading(false);
            var data = response.data.data.value.split('\r\n');
            console.log("data: ", data);
            setAboutUs(data);
        }).catch(err=>{
            console.error(err);
            // alert("Couldn't load about us.");
        })

    },[])

    

    return (
        <>
            <div hidden={!loading} className="container-fluid" style={{width:"100vw", height:"100vh",}}>
          <div className="row text-center">
              <div className="col my-auto text-center" style={{width:"100%", height:"100%", }}>
                  

                
                  {/* <img

                      src={Logo}
                    //   style={{width:"200px", height:"200px", borderRadius:"50%" , marginTop:"25vh"}} 
                    className="loading-logo"
                  /> */}
              </div>
          </div>
        </div>

        <div hidden={loading}>
        {
            (isMobile)? //render for Mobile Phones
            <div className="container-fluid" style={{width:"100%", padding:"0%", margin:"0px"}}>
               
            {/* <div className ="row" style={{maxWidth:"100%"}}> */}
            
                {/* <div  className ="row" style={{margin:"0", padding:"0"}}>
                  <img className='water' src={Water} alt="" style={{margin:"0", padding:"0"}}/>

                </div> */}
               
                <div  className ="row" style={{margin:"0", padding:"0"}}> {/* style={{maxWidth:"100%", width:"100", padding:"0%"}} */}
                    <div className="card-body">
                        <div className='about-head' style={{marginRight:"15%", marginLeft:"15%", padding:"0"}}>
                        
                        <div className='row text-center' style={{width:"100%", margin:"0", padding:"0"}}></div>
                            <h1 className='about-title'>
                                About Us
                            </h1>
                        <div/>
                     

                        <div className='row text-center' style={{width:"100%", margin:"0", padding:"0"}}>
                            <h1 className='about-main mb-5 mt-4'>
                                Abu Dhabi Malayalee
                                Samajam Since 1969
                            </h1>
                        </div>
                        <div className='row' style={{width:"100%", margin:"0", padding:"0"}}>
                            <div style={{wordWrap: "break-word"}}>
                                {/* <p className='about-description mb-3'>Being one of the escalating lands in terms of social welfare, quality of life, verdant, ecological diversity, education, literacy, art & cultural heritage, Kerala is renowned as the God’s own country with its beautiful land called as paradise. </p>
                                <p className='about-description'>We, Abu Dhabi Malayalee Samajam aims to promote and revive the traditions of the great land and maintain a gathering for the Keraliates residing in Abu Dhabi, UAE since 1969 swear the importance of the Kerala community in the country. Abudhabi Malayalee Samajam has inculcated in bringing morals and entice people in promoting the vivid traditions and ethics  from time to time. </p>
                                <p className='about-description'>We always encouraged an embracing attitude towards the people of Kerala and gave out the best possible in fulfilling the mission to zeal the rich culture and heritage along with humanitarian concerns. Food & Medicines are also supplied to the less-privileged people  who are really in need by organizing various fund-raising activities to help people facing crisis during  natural calamities.</p>
                                <p className='about-description'>Organizes several artistic, social events with traditional festivals including the prominent Onam feasts, ifthar parties, camps for children, seminars, conferences,  medical camps & check ups, blood donation camps,  much more.</p>
                                <p className='about-description'>During the pandemic times of Covid -19, we solely carried out worthy humanitarian aids to fight against the illness & crisis . </p>
                                <p className='about-description'>Sheikh Nahyan bin Mubarak Al Nahyan, Minister of Culture and Knowledge Development, inaugurated the new building of Abu Dhabi Malayalee Samajam in Mussafah in 2016. The building comprises a main hall, mini-hall, library & conference room. It also has badminton courts and playing area for kids.</p>
                                <p className='about-description'>Addressing the Keralite gathering he said: "Kerala is the most literate and socially-developed state in India. You have made a unique contribution to the peace and prosperity of the UAE."</p>
                            */}
                                        {
                                            aboutUs.map((line,index)=>{
                                                return( 
                                                    <p key={index} className='about-description'>{line}</p>
                                                    
                                                )
                                            })

                                        }
                                        
                                
                            </div>
                        </div>
                                                   
                                             
                         
                        </div>
                    </div>
                </div>
                <div className ="row" style={{margin:"0", padding:"0"}}> {/*style={{maxWidth:"100%", width:"100", padding:"0%"}} */}
                    <img className='aboutussImg' src={Keralam} alt="" style={{margin:"0", padding:"0", maxHeight:"500px"}} /> {/*className='aboutussImg' */}
                </div>

        {/* </div> */}
    </div>

            : //render for PCs

            <div className="container" style={{width:"100%", maxWidth:"100%", padding:"0%", margin:"0%"}}>
               
                    <div className ="row" style={{width:"100%", maxWidth:"100%", padding:"0%", margin:"0%"}}>
                    
                        {/* <div className='col' style={{maxWidth: "25%", width:"25%", padding:"0%", margin:"0%"}}>
                          <img className='water' src={Water} alt="" />

                        </div> */}
                       
                        <div className="col" style={{maxWidth: "50%", }}>
                            <div className="card-body">
                                <div className='about-head' style={{marginRight:"0%", width:"95%", padding:"0%", marginLeft:"5%"}}>
                                    <div className='row' style={{width:"100%"}}>
                                        <h1 className='about-title'>
                                            About Us
                                        </h1>
                                    </div>
                               
                               <div className='row' style={{width:"100%"}}>
                                    <h1 className='about-main mb-5 mt-4'>
                                        Abu Dhabi Malayalee
                                        Samajam Since 1969
                                    </h1>
                               </div>
                                 
                               <div className='row' style={{width:"100%"}}>
                                    <div style={{wordWrap: "break-word"}}>
                                    {/* <p className='about-description mb-3'>Being one of the escalating lands in terms of social welfare, quality of life, verdant, ecological diversity, education, literacy, art & cultural heritage, Kerala is renowned as the God’s own country with its beautiful land called as paradise. </p>
                                        <p className='about-description'>We, Abu Dhabi Malayalee Samajam aims to promote and revive the traditions of the great land and maintain a gathering for the Keraliates residing in Abu Dhabi, UAE since 1969 swear the importance of the Kerala community in the country. Abudhabi Malayalee Samajam has inculcated in bringing morals and entice people in promoting the vivid traditions and ethics  from time to time. </p>
                                        <p className='about-description'>We always encouraged an embracing attitude towards the people of Kerala and gave out the best possible in fulfilling the mission to zeal the rich culture and heritage along with humanitarian concerns. Food & Medicines are also supplied to the less-privileged people  who are really in need by organizing various fund-raising activities to help people facing crisis during  natural calamities.</p>
                                        <p className='about-description'>Organizes several artistic, social events with traditional festivals including the prominent Onam feasts, ifthar parties, camps for children, seminars, conferences,  medical camps & check ups, blood donation camps,  much more.</p>
                                        <p className='about-description'>During the pandemic times of Covid -19, we solely carried out worthy humanitarian aids to fight against the illness & crisis . </p>
                                        <p className='about-description'>Sheikh Nahyan bin Mubarak Al Nahyan, Minister of Culture and Knowledge Development, inaugurated the new building of Abu Dhabi Malayalee Samajam in Mussafah in 2016. The building comprises a main hall, mini-hall, library & conference room. It also has badminton courts and playing area for kids.</p>
                                        <p className='about-description'>Addressing the Keralite gathering he said: "Kerala is the most literate and socially-developed state in India. You have made a unique contribution to the peace and prosperity of the UAE."</p>
                           */}

                                        {
                                            aboutUs.map((line, index)=>{
                                                return(
                                                    <p key={index} className='about-description' style={{overflowWrap:"break-word"}}>{line}</p>
                                                )
                                            })
                                            
                                        }
                                    </div>
                                </div>
                                  

                                </div>
                            </div>
                        </div>
                        <div className="col" style={{maxWidth: "50%", width:"50%", padding:"0%", margin:"0%"}}>
                            <img className='aboutussImg' style={{maxHeight:"800px"}} src={Keralam} alt="" /> {/*className='aboutussImg' */}
                        </div>

                </div>
            </div>

        }
        </div>
        </>

    )



}