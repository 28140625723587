




export const setPagesSession = (pageName) =>{
    var pages = window.sessionStorage.getItem("pages");
    if(Boolean(pages)){
        var pages = JSON.parse(pages);
        var pkeys = Object.getOwnPropertyNames(pages);
        pkeys = pkeys.filter(p=> p != pageName)
        pkeys.forEach(p => {
            delete pages[p];
        });
        window.sessionStorage.setItem('pages', JSON.stringify(pages));
    }
}