import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getField = async (fieldname) => {
    const response = await axios.get(API_BASE_URL+'/api/otherfields/'+fieldname);
    return response;
}

export const editField = async (fieldname, formData) => {
    const response = await axios.post(API_BASE_URL+'/api/otherfields/'+fieldname+'/edit', formData);
    return response;
}