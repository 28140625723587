import { useState } from 'react'
import { useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import dummyImg from '../../Images/aboutbgImg.jpg'
import { getField } from '../../services/general-fields'
import './eventDummy.css'




export default function EventDummy({setReadyEventDummy}) {
  
  const [news,setNews] = useState([]);
  const [title, setTitle] = useState('');
  const screen1000 = window.visualViewport.width<1000;
  const [readyComponents, setReadyComponents] = useState(0);
  useEffect(()=>{
    getField('News Page').then(response=>{
      //setReadyComponents(readyComponents+ 0.5)
      var data = response.data.data.value.split('\r\n');
      console.log("data: ", data);
      // if(!Boolean(data[0])) setReadyEventDummy(true);
      setReadyEventDummy(true);
      setTitle(data[0]);
      setNews(data.slice(1,data.length));
      // setNews(data.slice(1,data.length).join('\r\n'));
      
  }).catch(err=>{
      console.error(err);
      // alert("Couldn't load about us.");
      setReadyEventDummy(true);
  })

  },[])

  // useEffect(()=>{
  //   if(readyComponents == 1)
  //     setReadyEventDummy(true)

  // },[readyComponents])


  // return (
  //   <>
  //     <div className="container event-dummy" >
  //       <Card className="bg-dark text-white mt-5">
  //         <Card.Img src={dummyImg} alt="Card image"  />
  //         <Card.ImgOverlay style={{width:"100%", height:"inherit"}}>
  //           <Card.Title className='dummy-tittle' style={{color: "black"}}>PROUD MOMENT OF ABUDHABI MALAYALEE SAMAJAM</Card.Title>
  //           <Card.Text className='dummy-text' style={{color: "black"}}>
  //           We are always pleased to recollect the memory of our AbuDhabi Malayalee Samajam new building inauguration in Mussafah. The inauguration was done by Shaikh Nahyan bin Mubarak Al Nahyan Minister of Culture and Knowledge Development, on 30th September 2016. Shaikh Nahyan remembered that the Indian Association, founded in 1969, is older than the UAE. In addition to honoring the distinctive contribution to peace, prosperity & growth of the UAE. 

  //           The Samajam president was Yesuseelan B at that time. On that day, he recalled how they raised funds for the building & thanked each & every patron who helped in this effort. Chief patron MA Yusef Ali also pointed out during the ceremony that the building was a collective effort.

  //           Abu Dhabi Malayalee Samajam was so proud of that moment of achieving a building with the main hall, mini-hall, library, and conference room along with badminton courts and a playing area for children from the tent-like structure in Madinat Zayed in the 1970s.
  //           </Card.Text>
  //           <Card.Text className='dummy-text'>Last updated 3 mins ago</Card.Text>
  //         </Card.ImgOverlay>
  //       </Card>
        
  //     </div>
  //   </>
  // )



  return (
    <>
    {
      
      //   <div id='div-container' style={{display: "flex", flexDirection:"column", position:"relative", marginRight:"10%", marginLeft:"10%", marginBottom:"3%" }}  >
        // <img src={dummyImg} alt="First slide" 
        //   style={{
        //     width: "100%",
        //     height: "100%",
        //     backfaceVisibility: "hidden",
        //     borderRadius: "3%",
        //     objectFit: "fill",}}
        // />
        // <div id='div-text' style={{ wordWrap: "break-word", height:"auto",position:"absolute", marginLeft:"1%", marginRight:"1%"}}>
        //     <p className='dummy-tittle' style={{color: "black", marginTop:"3%", marginBottom:"1%", textAlign:"center", }}>{title}</p> 

        //   {
           
        //     news.map((line, index)=>{
        //       return(
        //         <p key={index} className='dummy-text' style={{marginBottom:"3%", color:"black",}}>{line}</p>
        //       )
        //     })
           
        //   } 

         
        // </div>
      // </div>
      (screen1000)?
      <div className="container-fluid" id='div-container' style={{margin:"0", paddingTop:"2%", width:"100%", marginBottom:"2%", }}>
        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
          <div className="col" style={{paddingLeft:"10%", paddingRight:"10%"}}>
          <div className='text-section' style={{marginRight:"0%", width:"95%", padding:"0%", marginLeft:"5%", }}>
            <div id='div-text' style={{ wordWrap: "break-word", height:"auto", marginLeft:"1%", marginRight:"1%",}}>
              
              
              <p className='dummy-tittle' style={{color: "black", marginTop:"3%", marginBottom:"1%", textAlign:"center",}}>{title}</p> 

                {
                
                  news.map((line, index)=>{
                    return(
                      <p key={index} className='dummy-text' style={{marginBottom:"3%", color:"black", overflowWrap:"break-word"}}>{line}</p>
                    )
                  })
                
                } 

          
            </div>
            </div>
{/* 
                        <div className='row' style={{width:"100%", margin:"0", padding:"0"}}>
                            <div style={{wordWrap: "break-word"}}>
                             
                                        {
                                            news.map((line,index)=>{
                                                return( 
                                                    <p key={index} className='about-description'>{line}</p>
                                                    
                                                )
                                            })

                                        }
                                        
                                
                            </div>
                        </div> */}

            
          </div>
        </div>
        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>


        <div className="col" style={{width:"100%", paddingLeft:"10%", paddingRight:"10%"}}>
          <img src={dummyImg} alt="First slide" 
          //onLoad={()=> setReadyComponents(readyComponents+ 0.5)}
          style={{
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            borderRadius: "3%",
            objectFit: "fill",}}
            />
          </div>
        </div>
      
      </div>
    

      :
      <div className="container-fluid" id='div-container' style={{margin:"0", paddingTop:"2%", width:"100%", marginBottom:"2%",}}>
        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
          <div className="col" style={{width:"49%", marginRight:"2%"}}>
          <div className='text-section' style={{marginRight:"0%", width:"95%", padding:"0%", marginLeft:"5%"}}>
            <div id='div-text' style={{ wordWrap: "break-word", height:"auto", marginLeft:"1%", marginRight:"1%"}}>
              
              <p className='dummy-tittle' style={{color: "black", marginTop:"3%", marginBottom:"1%", textAlign:"center", }}>{title}</p> 

                {
                
                  news.map((line, index)=>{
                    return(
                      <p key={index} className='dummy-text' style={{marginBottom:"3%", overflowWrap:"break-word"}}>{line}</p>
                    )
                  })
                
                } 

          
            </div>
            </div>
            
          </div>

          <div className="col-sm-1" style={{width:"49%",}}>
          <img src={dummyImg} alt="First slide" 
           
          style={{
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            borderRadius: "3%",
            objectFit: "fill",}}
        />
          </div>
        </div>
      </div>  

     
    
        }

        </>
    







  )










}