import axios from "axios";
import { API_BASE_URL } from "../constants";



export const getImages = async (categoryName)=>{
    const response = await axios.get(API_BASE_URL+'/api/images/'+categoryName);
    return response;
}

export const addImages = async (formData)=>{
    const response = await axios.post(API_BASE_URL+'/api/images/add', formData);
    return response;
}

export const deleteImage = async (imageId)=>{
    const response = await axios.delete(API_BASE_URL+'/api/images/'+imageId+'/delete');
    return response;
}

export const addCategory = async (data)=>{
    const response = await axios.post(API_BASE_URL+'/api/imagecategories/add', data);
    return response;
}

export const getCategories = async ()=>{
    const response = await axios.get(API_BASE_URL+'/api/imagecategories');
    return response;
}

export const deleteCategory = async (categoryId)=>{
    const response = await axios.delete(API_BASE_URL+'/api/imagecategories/'+categoryId+'/delete');
    return response;
}







