import axios from "axios";
import { API_BASE_URL } from "../constants";

export const getActivities = async ()=>{
    const response = await axios.get(API_BASE_URL+'/api/activities');
    return response;
}

export const getActivity = async (activityId)=>{
    const response = await axios.get(API_BASE_URL+'/api/activities/'+activityId);
    return response;
}


export const addActivity = async (formData)=>{
    const response = await axios.post(API_BASE_URL+'/api/activities/add', formData);
    return response;
}

export const deleteActivity = async (activityId) =>{
    const response = await axios.delete(API_BASE_URL+'/api/activities/'+activityId+'/delete');
    return response;
}

export const editActivity = async (activityId, formData) =>{
    const response = await axios.post(API_BASE_URL + '/api/activities/'+activityId+'/edit', formData);
    return response;
}