
// import React from "react";
// import './managementSection.css'
// import contactImg1 from '../../Images/contactImg1.jpg'
// import contactImg2 from '../../Images/contactImg2.jpg'
// import contactImg3 from '../../Images/contactImg3.jpg'
// import contactImg4 from '../../Images/contactImg4.jpg'
// import { useState } from "react";
// import { API_BASE_URL } from "../../constants";
// import { getManagers } from "../../services/management";
// import { useEffect } from "react";

// function ManagementSection() {
    // const [management, setManagement] = useState([]);

    // useEffect(()=>{
    //     getManagers().then(respond =>{
    //         setManagement(respond.data.data);
    //     }).catch(err=>{
    //         console.error(err);
    //         alert("Couldn't load managers.");
    //     })
    // },[])

//     return (
//         <>

//             <div className="contact-section">
//                 <div className="container-fluid">
//                     <div className="row justify-content-center">
//                         <div className="col-12 col-lg-8 text-center">
//                             <span className="contact-section-sub">COME JOIN WITH us</span>
//                             <h2 className="contact-section-head">MANAGING COMMITTEE</h2>
//                         </div>

//                     </div>
//                 </div>
             
//                     <div className="row" style={{margin:"0", padding:"0",}}>
                        
//                         <div className="col-12 col-lg-12 contact-address">
//                             <div className="row ">
//                                 {
//                                     management.map(manager=>{
//                                         return (
//                                             <div className='col-md-3  head-address-section'>
//                                                 <div className='address-title'>
//                                                     <img className="contact-img" src={API_BASE_URL+'/images/'+manager.image.path} alt="" />
                                                    // <h6 className="contact-name">{manager.name}</h6>
                                                    // <p className="contact-position">
                                                    //     <small className='address-text-muted'>
                                                    //         {manager.role}
                                                    //     </small>
                                                    // </p>
                                                
                                                    // <p className="contact-mail">
                                                    //     <small className='address-text-muted'>{manager.email}</small>
                                                    // </p>
//                                                 </div>
//                                             </div>
//                                         )
//                                     })
//                                 }


//                                 {/*
                                
//                                  <div className='col-md-3  head-address-section'>
//                                                 <div className='address-title'>
//                                                     <img className="contact-img" src={API_BASE_URL+'/images/'+manager.image.path} alt="" />
//                                                     <h6 className="contact-name">{manager.name}</h6>
//                                                     <p className="contact-position">
//                                                         <small className='address-text-muted'>
//                                                             {manager.role}
//                                                         </small>
//                                                     </p>
                                                
//                                                     <p className="contact-mail">
//                                                         <small className='address-text-muted'>{manager.email}</small>
//                                                     </p>
//                                                 </div>
//                                             </div>
                                                                
                               

//                                <div className='col-md-3 head-address-section'>

//                                     <div className='address-title '>
//                                         <img className="contact-img" src={contactImg2} alt="" />
//                                         <h6 className="contact-name">REKHIN SOMAN</h6>
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>
//                                                 VICE-PRESIDENT
//                                             </small>
//                                         </p>
                                       
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>example@gmail.com</small>
//                                         </p>
//                                     </div>
//                                 </div>
//                                 <div className='col-md-3  head-address-section'>
//                                     <div className='address-title'>
//                                         <img className="contact-img" src={contactImg3} alt="" />

//                                         <h6 className="contact-name">IRSHAD M U</h6>
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>
//                                                 GENERAL SECRETARY
//                                             </small>
//                                         </p>
                                        
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>example@gmail.com</small>
//                                         </p>                                    </div>
//                                 </div>
//                                 <div className='col-md-3  head-address-section'>
//                                     <div className='address-title'>
//                                         <img className="contact-img" src={contactImg4} alt="" />

//                                         <h6 className="contact-name">AJAS APPADATH</h6>
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>
//                                             TREASURER
//                                             </small>
//                                         </p>
                                      
//                                         <p className="contact-position">
//                                             <small className='address-text-muted'>example@gmail.com</small>
//                                         </p>
//                                     </div>
//                                 </div> */}
//                             </div>
//                         </div>

//                     </div>
//                 </div>
         

//         </>
//     )
// }

// export default ManagementSection; 













import React from "react";
import './managementSection.css'
import contactImg1 from '../../Images/contactImg1.jpg'
import contactImg2 from '../../Images/contactImg2.jpg'
import contactImg3 from '../../Images/contactImg3.jpg'
import contactImg4 from '../../Images/contactImg4.jpg'
import { useState } from "react";
import { API_BASE_URL, IMAGES_URL } from "../../constants";
import { getManagers } from "../../services/management";
import { useEffect } from "react";
import Logo from '../../Images/Logo.jpg';
import '../../Pages/loadinglogo.css';

export default function ManagementSection() {
    const [management, setManagement] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imgsCount, setImgsCount] = useState(0);
    var readyImgs = 0;

    useEffect(()=>{
        getManagers().then(response =>{
            // setLoading(false)
            if(response.data.data.length == 0) setLoading(false);
            setManagement(response.data.data);
            setImgsCount(response.data.data.length);

        }).catch(err=>{
            console.error(err);
            setLoading(false);
            // alert("Couldn't load managers.");
        })
    },[])

   function onImgLoad(){
    readyImgs += 1;
    if(readyImgs == imgsCount) setLoading(false);
   }

    return (
        <>
               <div hidden={!loading} className="container-fluid" style={{width:"100vw", height:"100vh",}}>
          <div className="row text-center">
              <div className="col my-auto text-center" style={{width:"100%", height:"100%", }}>
                  

                
                  {/* <img

                      src={Logo}
                    //   style={{width:"200px", height:"200px", borderRadius:"50%" , marginTop:"25vh"}} 
                    className="loading-logo"
                  /> */}
              </div>
          </div>
        </div>

                 {/* <div className="contact-section"> */}
                <div hidden={loading} className="container-fluid" style={{margin:"0", padding:"0", width:"100%", height:"100%",}}>
               
                    <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                        <div className="col text-center">
                            <span className="contact-section-sub">COME JOIN WITH us</span>
                            <h2 className="contact-section-head">MANAGING COMMITTEE</h2>
                        </div>
                    </div>

                    <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                        
                        <div className="col-12 col-lg-12 contact-address" style={{margin:"0", padding:"0", width:"100%"}}>
                            <div className="row" style={{margin:"0", padding:"0", width:"100%"}}>
                                {
                                    management.map((manager)=>{
                                        return (
                                            <>
                                            <div key={manager.id} className='col-md-3  head-address-section'>
                                                    <div className="row">
                                                        <div className="col text-center"  style={{margin:"0", padding:"0", width:"100%"}}>
                                                            <img onLoad={onImgLoad} className="contact-img" src={IMAGES_URL+'/'+manager.image.path} alt="" />
                                                        </div>
                                                    </div>

                                                    <div className="row" style={{marginBottom:"20%", marginRight:"0", marginLeft:"0", padding:"0",}}>
                                                        <div className="col text-center">
                                                            <h6 className="contact-name">{manager.name}</h6>
                                                            <p className="contact-position">
                                                                <small className='address-text-muted'>
                                                                    {manager.role}
                                                                </small>
                                                            </p>
                                                            
                                                            <p className="contact-mail">
                                                                <small className='address-text-muted'>{manager.email}</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                               
                                            </div>

                                            </>
                                        )
                                    })
                                }

                            </div>
                        </div>

                    </div>
                </div>

                </>

        
    )
}

