import { useEffect } from 'react';
import { Router, Route, Switch, Navigate, Routes, useNavigate, } from 'react-router-dom';



export default function GalleryDashboard(){
	const navigate = useNavigate();
	useEffect(()=>{
		console.log("ttttttttttttttttttttt");
		navigate('/dashboard/gallery/categories');
	},[])
	return <></>

}

