// import './gallery.css'
// import React, { useState, useCallback } from "react";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";

// // import galleryImg1 from '../../Images/galleryImg1.jpg'
// // import galleryImg2 from '../../Images/galleryImg2.jpg'
// // import galleryImg3 from '../../Images/galleryImg3.jpg'
// // import galleryImg4 from '../../Images/galleryImg4.jpg'
// // import galleryImg5 from '../../Images/galleryImg5.jpg'
// // import galleryImg6 from '../../Images/galleryImg6.jpg'
// // import galleryImg7 from '../../Images/galleryImg7.jpg'
// // import galleryImg8 from '../../Images/galleryImg8.jpg'
// // import galleryImg9 from '../../Images/galleryImg9.jpg'
// // import galleryImg10 from '../../Images/galleryImg10.jpg'
// // import galleryImg11 from '../../Images/galleryImg11.jpg'
// // import galleryImg12 from '../../Images/galleryImg12.jpg'
// // import galleryImg13 from '../../Images/galleryImg13.jpg'
// // import galleryImg14 from '../../Images/galleryImg14.jpg'
// // import galleryImg15 from '../../Images/galleryImg15.jpg'
// // import galleryImg16 from '../../Images/galleryImg16.jpg'
// // import galleryImg17 from '../../Images/galleryImg17.jpg'
// // import galleryImg18 from '../../Images/galleryImg18.jpg'
// // import galleryImg19 from '../../Images/galleryImg19.jpg'
// // import galleryImg20 from '../../Images/galleryImg20.jpg'
// // import galleryImg21 from '../../Images/galleryImg21.jpg'
// // import galleryImg22 from '../../Images/galleryImg22.jpg'
// // import galleryImg23 from '../../Images/galleryImg23.jpg'
// // import galleryImg24 from '../../Images/galleryImg24.jpg'
// // import galleryImg25 from '../../Images/galleryImg25.jpg'
// // import galleryImg26 from '../../Images/galleryImg26.jpg'
// // import galleryImg27 from '../../Images/galleryImg27.jpg'
// // import galleryImg28 from '../../Images/galleryImg28.jpg'
// // import galleryImg29 from '../../Images/galleryImg29.jpg'
// // import galleryImg30 from '../../Images/galleryImg30.jpg'
// // import galleryImg31 from '../../Images/galleryImg31.jpg'
// // import galleryImg32 from '../../Images/galleryImg32.jpg'
// // import galleryImg33 from '../../Images/galleryImg33.jpg'
// // import galleryImg34 from '../../Images/galleryImg34.jpg'
// // import galleryImg35 from '../../Images/galleryImg35.jpg'
// // import galleryImg36 from '../../Images/galleryImg36.jpg'
// // import galleryImg37 from '../../Images/galleryImg37.jpg'
// // import galleryImg38 from '../../Images/galleryImg38.jpg'
// // import galleryImg39 from '../../Images/galleryImg39.jpg'
// // import galleryImg40 from '../../Images/galleryImg40.jpg'
// // import galleryImg41 from '../../Images/galleryImg41.jpg'
// // import galleryImg42 from '../../Images/galleryImg42.jpg'
// // import galleryImg43 from '../../Images/galleryImg43.jpg'
// // import galleryImg44 from '../../Images/galleryImg44.jpg'
// // import galleryImg45 from '../../Images/galleryImg45.jpg'
// // import galleryImg46 from '../../Images/galleryImg46.jpg'
// // import galleryImg47 from '../../Images/galleryImg47.jpg'
// // import galleryImg48 from '../../Images/galleryImg48.jpg'
// // import galleryImg49 from '../../Images/galleryImg49.jpg'
// // import galleryImg50 from '../../Images/galleryImg50.jpg'
// // import galleryImg51 from '../../Images/galleryImg51.jpg'
// // import galleryImg52 from '../../Images/galleryImg52.jpg'
// // import galleryImg53 from '../../Images/galleryImg53.jpg'
// // import galleryImg54 from '../../Images/galleryImg54.jpg'
// // import galleryImg55 from '../../Images/galleryImg55.jpg'
// // import galleryImg56 from '../../Images/galleryImg56.jpg'
// // import galleryImg57 from '../../Images/galleryImg57.jpg'
// // import galleryImg58 from '../../Images/galleryImg58.jpg'
// // import galleryImg59 from '../../Images/galleryImg59.jpg'
// // import galleryImg60 from '../../Images/galleryImg60.jpg'
// // import galleryImg61 from '../../Images/galleryImg61.jpg'
// // import galleryImg62 from '../../Images/galleryImg62.jpg'
// // import galleryImg63 from '../../Images/galleryImg63.jpg'
// // import galleryImg64 from '../../Images/galleryImg64.jpg'
// // import galleryImg65 from '../../Images/galleryImg65.jpg'
// // import galleryImg66 from '../../Images/galleryImg66.jpg'
// // import galleryImg67 from '../../Images/galleryImg67.jpg'
// // import galleryImg68 from '../../Images/galleryImg68.jpg'
// // import galleryImg69 from '../../Images/galleryImg69.jpg'
// // import galleryImg70 from '../../Images/galleryImg70.jpg'
// // import galleryImg71 from '../../Images/galleryImg71.jpg'
// // import galleryImg72 from '../../Images/galleryImg72.jpg'
// // import galleryImg73 from '../../Images/galleryImg73.jpg'
// // import galleryImg74 from '../../Images/galleryImg74.jpg'
// // import galleryImg75 from '../../Images/galleryImg75.jpg'
// // import galleryImg76 from '../../Images/galleryImg76.jpg'
// // import galleryImg77 from '../../Images/galleryImg77.jpg'
// // import galleryImg78 from '../../Images/galleryImg78.jpg'
// // import galleryImg79 from '../../Images/galleryImg79.jpg'
// // import galleryImg80 from '../../Images/galleryImg80.jpg'
// // import galleryImg81 from '../../Images/galleryImg81.jpg'
// // import galleryImg82 from '../../Images/galleryImg82.jpg'
// // import galleryImg83 from '../../Images/galleryImg83.jpg'
// // import galleryImg84 from '../../Images/galleryImg84.jpg'
// // import galleryImg85 from '../../Images/galleryImg85.jpg'
// // import galleryImg86 from '../../Images/galleryImg86.jpg'
// // import galleryImg87 from '../../Images/galleryImg87.jpg'
// // import galleryImg88 from '../../Images/galleryImg88.jpg'
// // import galleryImg89 from '../../Images/galleryImg89.jpg'
// // import galleryImg90 from '../../Images/galleryImg90.jpg'
// import { useNavigate } from 'react-router-dom';
// import { useEffect } from 'react';
// import { setPagesSession } from '../../Components/general-functions/helpers';

// /**************************Art ************************* */
// import galleryImg54 from '../../Images/art/galleryImg54.jpg'
// import galleryImg55 from '../../Images/art/galleryImg55.jpg'
// import galleryImg56 from '../../Images/art/galleryImg56.jpg'
// import galleryImg58 from '../../Images/art/galleryImg58.jpg'
// import galleryImg57 from '../../Images/art/galleryImg57.jpg'
// import galleryImg62 from '../../Images/art/galleryImg62.jpg'
// import galleryImg63 from '../../Images/art/galleryImg63.jpg'
// import culturalInviteActivity from '../../Images/art/culturalInviteActivity.jpg'

// /*************************Award************************* */
// import galleryImg19 from '../../Images/award/galleryImg19.jpg';
// import galleryImg21 from '../../Images/award/galleryImg21.jpg'
// import galleryImg23 from '../../Images/award/galleryImg23.jpg'
// import galleryImg25 from '../../Images/award/galleryImg25.jpg'
// import galleryImg26 from '../../Images/award/galleryImg26.jpg'
// import galleryImg27 from '../../Images/award/galleryImg27.jpg'
// import galleryImg28 from '../../Images/award/galleryImg28.jpg'
// import galleryImg33 from '../../Images/award/galleryImg33.jpg'

// /*********************** career Guidance *****************/
// import galleryImg11 from '../../Images/career-guidance/galleryImg11.jpg'
// import galleryImg87 from '../../Images/career-guidance/galleryImg87.jpg'
// import galleryImg88 from '../../Images/career-guidance/galleryImg88.jpg'
// import galleryImg89 from '../../Images/career-guidance/galleryImg89.jpg'
// import seminarsActivity from '../../Images/career-guidance/seminarsActivity.jpg'

// /**********************  Cultural *************************/
// import galleryImg1 from '../../Images/cultural/galleryImg1.jpg'
// import galleryImg17 from '../../Images/cultural/galleryImg17.jpg'
// import galleryImg38 from '../../Images/cultural/galleryImg38.jpg'
// import galleryImg44 from '../../Images/cultural/galleryImg44.jpg'
// import galleryImg47 from '../../Images/cultural/galleryImg47.jpg'
// import galleryImg50 from '../../Images/cultural/galleryImg50.jpg'
// import galleryImg86 from '../../Images/cultural/galleryImg86.jpg'

// /*********************** Iftar **************************** */
// import galleryImg9 from '../../Images/iftar/galleryImg9.jpg'
// import galleryImg43 from '../../Images/iftar/galleryImg43.jpg'
// import galleryImg81 from '../../Images/iftar/galleryImg81.jpg'
// import galleryImg85 from '../../Images/iftar/galleryImg85.jpg'

// /************************ Medical**************************** */
// import galleryImg16 from '../../Images/medical/galleryImg16.jpg'
// import bloodDonationActivity from '../../Images/medical/bloodDonationActivity.jpg'
// import medicalCheckUpActivity from '../../Images/medical/medicalCheckUpActivity.jpg'

// /*********************** Seminar ***************************** */
// import galleryImg52 from '../../Images/seminar/galleryImg52.jpg'
// import galleryImg80 from '../../Images/seminar/galleryImg80.jpg'
// import galleryImg82 from '../../Images/seminar/galleryImg82.jpg'
// import galleryMainImg from '../../Images/seminar/galleryMainImg.jpg'

// /************************ Sport ****************************** */
// import galleryImg7 from '../../Images/sport/galleryImg7.jpg'
// import galleryImg65 from '../../Images/sport/galleryImg65.jpg'
// import galleryImg66 from '../../Images/sport/galleryImg66.jpg'
// import galleryImg67 from '../../Images/sport/galleryImg67.jpg'
// import galleryImg69 from '../../Images/sport/galleryImg69.jpg'
// import galleryImg68 from '../../Images/sport/galleryImg68.jpg'
// import galleryImg70 from '../../Images/sport/galleryImg70.jpg'
// import galleryImg71 from '../../Images/sport/galleryImg71.jpg'
// import galleryImg72 from '../../Images/sport/galleryImg72.jpg'
// import galleryImg73 from '../../Images/sport/galleryImg73.jpg'
// import galleryImg74 from '../../Images/sport/galleryImg74.jpg'
// import galleryImg75 from '../../Images/sport/galleryImg75.jpg'
// import galleryImg76 from '../../Images/sport/galleryImg76.jpg'
// import galleryImg77 from '../../Images/sport/galleryImg77.jpg'
// import galleryImg78 from '../../Images/sport/galleryImg78.jpg'
// import galleryImg79 from '../../Images/sport/galleryImg79.jpg'


// /************************ General ***************************** */
// import galleryImg2 from '../../Images/galleryImg2.jpg'
// import galleryImg3 from '../../Images/galleryImg3.jpg'
// import galleryImg6 from '../../Images/galleryImg6.jpg'
// import galleryImg8 from '../../Images/galleryImg8.jpg'
// import galleryImg10 from '../../Images/galleryImg10.jpg'
// import galleryImg15 from '../../Images/galleryImg15.jpg'
// import galleryImg18 from '../../Images/galleryImg18.jpg'
// import galleryImg20 from '../../Images/galleryImg20.jpg'
// import galleryImg22 from '../../Images/galleryImg22.jpg'
// import galleryImg24 from '../../Images/galleryImg24.jpg'
// import galleryImg29 from '../../Images/galleryImg29.jpg'
// import galleryImg30 from '../../Images/galleryImg30.jpg'
// import galleryImg31 from '../../Images/galleryImg31.jpg'
// import galleryImg32 from '../../Images/galleryImg32.jpg'
// import galleryImg34 from '../../Images/galleryImg34.jpg'
// import galleryImg35 from '../../Images/galleryImg35.jpg'
// import galleryImg36 from '../../Images/galleryImg36.jpg'
// import galleryImg37 from '../../Images/galleryImg37.jpg'
// import galleryImg39 from '../../Images/galleryImg39.jpg'
// import galleryImg40 from '../../Images/galleryImg40.jpg'
// import galleryImg41 from '../../Images/galleryImg41.jpg'
// import galleryImg42 from '../../Images/galleryImg42.jpg'
// import galleryImg45 from '../../Images/galleryImg45.jpg'
// import galleryImg46 from '../../Images/galleryImg46.jpg'
// import galleryImg48 from '../../Images/galleryImg48.jpg'
// import galleryImg49 from '../../Images/galleryImg49.jpg'
// import galleryImg51 from '../../Images/galleryImg51.jpg'
// import galleryImg53 from '../../Images/galleryImg53.jpg'
// import galleryImg59 from '../../Images/galleryImg59.jpg'
// import galleryImg60 from '../../Images/galleryImg60.jpg'
// import galleryImg61 from '../../Images/galleryImg61.jpg'
// import galleryImg64 from '../../Images/galleryImg64.jpg'
// import galleryImg83 from '../../Images/galleryImg83.jpg'
// import galleryImg84 from '../../Images/galleryImg84.jpg'
// import galleryImg90 from '../../Images/galleryImg90.jpg'


// export default function ViewImages() {
//     const navigate = useNavigate();
//     const [currentImage, setCurrentImage] = useState(0);
//     const [viewerIsOpen, setViewerIsOpen] = useState(false);

//     const openLightbox = useCallback((event, { photo, index }) => {
//         setCurrentImage(index);
//         setViewerIsOpen(true);
//     }, []);

//     const closeLightbox = () => {
//         setCurrentImage(0);
//         setViewerIsOpen(false);
//     };

//     const screen460 = window.visualViewport.width <= 460;
    
//     const imageStyle = (screen460) ?{
//         margin: 5,
//         display: 'flex',
//         cursor: 'pointer',
//         width: "45%",
//         height: 130,
//         borderRadius: 5,
//     } :{
//         margin: 5,
//         display: 'flex',
//         cursor: 'pointer',
//         width: 200,
//         height: 130,
//         borderRadius: 5,
//         justifyContent: "space-around"
        
//     };


//     const [category, setCategory] = useState(""); 
    
//     const sport = [
//           {src: galleryImg7, style: imageStyle, }, {src: galleryImg65, style: imageStyle }, {src: galleryImg66, style: imageStyle }, {src: galleryImg67, style: imageStyle }, {src: galleryImg68, style: imageStyle }, {src: galleryImg69, style: imageStyle }, {src: galleryImg70, style: imageStyle }, {src: galleryImg71, style: imageStyle }, {src: galleryImg72, style: imageStyle }, {src: galleryImg73, style: imageStyle }, {src: galleryImg74, style: imageStyle }, {src: galleryImg75, style: imageStyle }, {src: galleryImg76, style: imageStyle }, {src: galleryImg77, style: imageStyle }, {src: galleryImg78, style: imageStyle }, {src: galleryImg79, style: imageStyle }
//         ];

//     const medicals = [{src: galleryImg16, style: imageStyle, }, {src: bloodDonationActivity, style: imageStyle }, {src: medicalCheckUpActivity, style: imageStyle }];

//     const art = [{src: galleryImg54, style: imageStyle, }, {src: galleryImg55, style: imageStyle }, {src: galleryImg56, style: imageStyle },  {src: galleryImg58, style: imageStyle }, {src: galleryImg57, style: imageStyle }, {src: galleryImg62, style: imageStyle }, {src: galleryImg63, style: imageStyle }, {src: culturalInviteActivity, style: imageStyle },];

//     const iftarParties = [{src: galleryImg9, style: imageStyle, }, {src: galleryImg43, style: imageStyle }, {src: galleryImg81, style: imageStyle }, {src: galleryImg85, style: imageStyle },];

//     const culturals = [{src: galleryImg1, style: imageStyle, }, {src: galleryImg17, style: imageStyle }, {src: galleryImg38, style: imageStyle }, {src: galleryImg44, style: imageStyle }, {src: galleryImg47, style: imageStyle }, {src: galleryImg50, style: imageStyle }, {src: galleryImg86, style: imageStyle }];

//     const seminars = [{src: galleryImg52, style: imageStyle, }, {src: galleryImg80, style: imageStyle }, {src: galleryImg82, style: imageStyle }, {src: galleryImg16, style: imageStyle }, {src: galleryMainImg, style: imageStyle }];

//     const awards = [{src: galleryImg19, style: imageStyle, }, {src: galleryImg21, style: imageStyle }, {src: galleryImg23, style: imageStyle },  {src: galleryImg25, style: imageStyle }, {src: galleryImg26, style: imageStyle }, {src: galleryImg27, style: imageStyle }, {src: galleryImg28, style: imageStyle }, {src: galleryImg33, style: imageStyle },];

//     const careerGuidance = [{src: galleryImg11, style: imageStyle, }, {src: galleryImg87, style: imageStyle }, {src: galleryImg86, style: imageStyle }, {src: galleryImg89, style: imageStyle }, {src: seminarsActivity, style: imageStyle }, ];
    
//     const general = [{src: galleryImg2, style: imageStyle },{src: galleryImg3, style: imageStyle },{src: galleryImg6, style: imageStyle },{src: galleryImg8, style: imageStyle },{src: galleryImg10, style: imageStyle },{src: galleryImg15, style: imageStyle },{src: galleryImg18, style: imageStyle },{src: galleryImg20, style: imageStyle },{src: galleryImg22, style: imageStyle },{src: galleryImg24, style: imageStyle },{src: galleryImg29, style: imageStyle },{src: galleryImg30, style: imageStyle },{src: galleryImg31, style: imageStyle },{src: galleryImg32, style: imageStyle },{src: galleryImg34, style: imageStyle }, {src: galleryImg35, style: imageStyle }, {src: galleryImg36, style: imageStyle },{src: galleryImg37, style: imageStyle },{src: galleryImg39, style: imageStyle },{src: galleryImg40, style: imageStyle },{src: galleryImg41, style: imageStyle },{src: galleryImg42, style: imageStyle },{src: galleryImg45, style: imageStyle },{src: galleryImg46, style: imageStyle },{src: galleryImg48, style: imageStyle },{src: galleryImg49, style: imageStyle }, {src: galleryImg51, style: imageStyle },{src: galleryImg53, style: imageStyle },{src: galleryImg59, style: imageStyle },{src: galleryImg60, style: imageStyle },{src: galleryImg61, style: imageStyle },{src: galleryImg64, style: imageStyle },{src: galleryImg83, style: imageStyle }, {src: galleryImg84, style: imageStyle }, {src: galleryImg90, style: imageStyle },]

//     const categories = {general: general, Art: art, Sport: sport, Medicals: medicals, "Iftar Parties": iftarParties, Culturals: culturals, Seminars: seminars, Awards: awards, "Career Guidance": careerGuidance}

//     useEffect(()=>{
//         window.scrollTo(0,0);
//     },[])

//     useEffect(()=>{
//           setPagesSession("galleryimages");
//           var pages = window.sessionStorage.getItem("pages");
//           if(Boolean(pages)){
//             pages = JSON.parse(pages);
//             if(Boolean(pages.galleryimages) && Boolean(pages.galleryimages.category)){
//                 setCategory(pages.galleryimages.category);
//             }
//             else{
//                 navigate('/gallery');
//             }
//             // var pkeys = Object.getOwnPropertyNames(pages);
//             // pkeys = pkeys.filter(p=> p != "galleryimages")
//             // pkeys.forEach(p => {
//             //     delete pages[p];
//             // });
//             // window.sessionStorage.setItem("pages", JSON.stringify(pages));
//           }
//           else{
//             navigate('/gallery');

//           }
        
  


//         console.log("current category: ", category)
//         // if(!Boolean(category)){
//         //     navigate('/gallery');
//         // }

       
//     },[])







//     // const photos = [

//     //     {
//     //         src: galleryImg1,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg2,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg3,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg4,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg5,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg6,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg7,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg8,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg9,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg10,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg11,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg12,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg13,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg14,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg15,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg16,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg17,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg18,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg19,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg20,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg21,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg22,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg23,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg24,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg25,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg26,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg27,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg28,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg29,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg30,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg31,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg32,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg33,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg34,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg35,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg36,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg37,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg38,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg39,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg40,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg41,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg42,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg43,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg44,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg45,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg46,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg47,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg48,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg49,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg50,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg51,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg52,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg53,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg54,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg55,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg56,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg57,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg58,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg59,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg60,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg61,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg62,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg63,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg64,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg65,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg66,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg67,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg68,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg69,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg70,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg71,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg72,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg73,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg74,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg75,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg76,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg77,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg78,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg79,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg80,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg81,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg82,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg83,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg84,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg85,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg86,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg87,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg88,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg89,
//     //         style: imageStyle

//     //     },
//     //     {
//     //         src: galleryImg90,
//     //         style: imageStyle

//     //     },

//     // ];



//     return (
//         <>
//         {(Boolean(category))?
//             <div className='container mb-5'>
//                 <div className="row">
//                     <div className="col text-center">
//                         <h1 className='galleryy-title '><span className='head-design'>{category}</span> </h1>

//                         <Gallery photos={categories[category]} onClick={openLightbox} />
//                         <ModalGateway>
//                             {viewerIsOpen ? (
//                                 <Modal   onClose={closeLightbox}>
//                                     <Carousel
//                                         currentIndex={currentImage}
//                                         views={categories[category].map(x => ({
//                                             ...x,
//                                             srcset: x.srcSet,
//                                             caption: x.title
//                                         }))}
//                                     />
//                                 </Modal>
//                             ) : null}
//                         </ModalGateway>
//                     </div>
//                 </div>
                

//             </div>
//             :
//             <></>
//         }
//         </>
//     );
// }














import '../Gallery/gallery.css'
import '../../Components/HomeGalleryy/homeGalleryy.css'

import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { setPagesSession } from '../../Components/general-functions/helpers'
import { addImages, deleteImage, getImages } from '../../services/images';
import { API_BASE_URL, IMAGES_URL } from '../../constants';
import Logo from '../../Images/Logo.jpg'
import '../loadinglogo.css';


export default function ViewImages() {
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    
    const [imgsCount, setImgsCount] = useState(0);
    var readyImgs = 0;

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const screen460 = window.visualViewport.width <= 460;
    
    const imageStyle = (screen460) ?{
        margin: 5,
        display: 'flex',
        cursor: 'pointer',
        width: 70,
        height: 100,
        borderRadius: 5,
    } :{
        margin: 5,
        display: 'flex',
        cursor: 'pointer',
        width: 200,
        height: 130,
        borderRadius: 5,
        justifyContent: "space-around"
        
    };


    const [category, setCategory] = useState(""); 
    

    const [images, setImages] = useState([]);

    // function onImgLoad(){
    //     console.log("an img is loaded")
    //     readyImgs+=1;
    //     if(readyImgs == imgsCount) setLoading(false);
    // }

    useEffect(()=>{

        if(category){        
            console.log("loading images...");
            getImages(category).then(response=>{
                // if(response.data.data.length==0) setLoading(false);
                setLoading(false);


               var imgs = [];
                response.data.data.forEach(img=>{
                    imgs.push({ src: IMAGES_URL+'/'+ category +'/'+img.path, style: imageStyle, id:img.id, });//onLoad: onImgLoad
                })
                setImages(imgs);
            }).catch(err=>{
                console.error(err);
                // alert("Couldn't load images");
                // setLoading(false);

            });
            
        }
        
    },[category])


    useEffect(()=>{
        window.scrollTo(0,0);
          setPagesSession("galleryimages");
          var pages = window.sessionStorage.getItem("pages");
          if(Boolean(pages)){
            pages = JSON.parse(pages);
            if(Boolean(pages.galleryimages) && Boolean(pages.galleryimages.category)){
                setCategory(pages.galleryimages.category);
            }
            else{
                navigate('/dashboard/gallery');
            }
        
          }
          else{
            navigate('/dashboard/gallery');

          }
        
  


        console.log("current category: ", category)
    
       
    },[])

   

   

    return (
        <>
        {(Boolean(category))?
        <>
             <div hidden={!loading} className="container-fluid" style={{width:"100vw", height:"100vh",}}>
          <div className="row text-center">
              <div className="col my-auto text-center" style={{width:"100%", height:"100%", }}>
                  

                
                  {/* <img

                      src={Logo}
                    //   style={{width:"200px", height:"200px", borderRadius:"50%" , marginTop:"25vh"}} 
                    className="loading-logo"
                  /> */}
              </div>
          </div>
        </div>

            <div hidden={loading} className='container mb-5'>
                <div className="row">
                    <div className="col text-center">
                        <h1 className='galleryy-title '><span className='head-design'>{category}</span> </h1>

                        <Gallery photos={images} onClick={openLightbox} />
                        <ModalGateway>
                            {viewerIsOpen ? (
                                <Modal   onClose={closeLightbox}>
                                    <Carousel
                                        currentIndex={currentImage}
                                        views={images.map(x => ({
                                            ...x,
                                            srcset: x.srcSet,
                                            caption: x.title
                                        }))}
                                    />
                                </Modal>
                            ) : null}
                        </ModalGateway>
                    </div>
                </div>
               

            </div>
        </>
            :
            <></>
        }
        </>
    );
}


// <button type="button" className="btn gallery-btn" onClick={()=>onAddMore()}>{/*className="btn about-home-btn*/}
// Add <span className='title-design'>more</span>
// </button>






