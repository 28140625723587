import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getField } from "../../services/general-fields";
import './homeFlash.css'


function HomeFlash() {
    const [news, setNews] = useState('');

    useEffect(()=>{
        getField('News Bar').then(response=>{
            setNews(response.data.data);
        }).catch(err=>{
            console.error(err);
            // alert("Couldn't load News Bar.");
        });
    }, [])

    return (
        <>
            <div className="news-head">
                <div className='home-content p-4'>
                    <div className="opacity-medium bg-extra-dark-gray"></div>
                    <div id="logos-home-page">
                        <div class="slider">
                            <div class="slide-track">
                                <div class="slide">
                                <h1 className="news-title"><p className="flashnews-para">FLASH NEWS: <span className="flash-news-content">
                                    {news.value}</span></p></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default HomeFlash

//1.Abu Dhabi Malayalee Samajam’s (ADMS) sahithya puraskaram for the year 2018 has been awarded for Rafeeq Ahamed,  renowned malayalam poet and one of the most critically notable lyricist of Malayalam film industry 2.Media one Brave heart Award (2020) :  for the innumerous helping hand during covid-19 crisis in the regions of Mussafah, AbuDhabi 3.Malayalee Samajam mobile app : Enable the users in  handling memberships, bookings, registrations to event organizations from a single point of access keeping it updated.