import facilityImg from '../../Images/facilityImg.png'
import './homeFacility.css'
import { Parallax } from "react-parallax";


export default function HomeFacility() {

  const styles = {
    fontFamily: "sans-serif",
    textAlign: "center"
  };
  const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
  };

  return (
    <>
      <div className="facility-section" style={{width:"100%", margin:"0", padding:"0"}}>
        <div className='facility-img'>
          
        </div>
       
      </div>
    </>
  )
}