import '../../../Components/HomeActivities/homeActivities.css'
import React, { useState } from "react";
import { useEffect } from 'react';
import { addActivity, getActivities } from '../../../services/activities';
import { API_BASE_URL } from '../../../constants';
import { useNavigate } from "react-router-dom";


export default function ActivitiesDashboard() {

    const [activities, setActivities] = useState([]);
    const [activeActivity, setActiveActivity] = useState(0);

  
    useEffect(()=>{
        getActivities().then(response=>{
            setActivities(response.data.data);
            setCurrentImg(response.data.data[0].image.path)
        }).catch(err=>{
            console.log(err);
            // alert("Couldn't load activities.");
        })
    },[])
    


    const screen1000 = window.visualViewport.width <= 1000;

    const [currentImg, setCurrentImg] = useState('');
    const [newImageFile, setNewImagefile] = useState(null);
    const navigate = useNavigate();

    function handleAddActivity(){
        var data = new FormData();

       data.append('title', document.getElementById('title').value);
       data.append('text', document.getElementById('text').value);
       data.append('image', newImageFile[0]);

       addActivity(data).then(response=>{
        var activitiesClone = [...activities];
        activitiesClone.push(response.data.data);
        setActivities(activitiesClone);
        alert("Activity is added successfully");

       }).catch(err=>{
        console.log(err);
        alert("couldn't add the activity");
       })

    }

    function onEditClick(id){
        console.log("moving to edit "+id);
        window.sessionStorage.setItem('activity', id);
        navigate('/dashboard/activities/edit')
    }

    return (
        (screen1000)?
            <div className='container-fluid' style={{justifyContent: "center", marginBottom:"5%"}}>
                    <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                        <div className='col' style={{width:"100%", textAlign:"center", marginTop:"3%"}}>
                            <h5 className='activity-how-we-work'>How we work</h5>
                        </div>
                    </div>
                    
                    <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
                        <div className='col' style={{width:"100%", textAlign:"center"}}>
                            <h1 className='activity-text'>Abu Dhabi malayalee samajam activities</h1>
                        </div>
                    </div>

                    <div className="row" style={{width:"100%", margin:"0", padding:"0", textAlign:"center", justifyContent:"center"}}>
                        
                        <div className="col-lg-4 col-md-4 col-sm-12 activity-left-section d-flex flex-row"  > {/*className="col-sm-1 text-center" style={{width:"22%",padding:"0", marginRight:"3%"}} */}
                            <div className="d-flex activity-mob">
                                {
                                    activities.map((activity,index)=>{
                                        return(
                                            <>
                                          
                                            <div className='row' style={{marginBottom:"5%"}}>
                                                <div className="row">
                                                    <div className='col-sm-1' style={{width:"50%"}}>
                                                        <span className={(activeActivity !== index)? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
                                                            setActiveActivity(index);
                                                            setCurrentImg(activity.image.path);
                                                            }}>
                                                            {index+". "}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="row" >
                                                    <div className='row' style={{marginBottom:"3%", padding:"0",}}>
                                                        <div className="col-sm-1 " style={{width:"90%", height:"100%", padding:"0"}}>
                                                            <button onClick={()=>onEditClick(activity.id)} style={{ backgroundColor:"yellow", width:"100%", height:"100%"}}>Edit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                              
                                            </div>
                                            </>
                                        )
                                    })
                                }   
                             
                            </div>
 

                        </div>
                        <div className="col-lg-8 col-md-8 activity-right-section"> {/* */}
                            <div style={{marginTop:"5%"}}>
                                <div className="">
                                    <div className="activity-section">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col"> {/*className="col-lg-5" */}
                                                    <h1 className="activity-head">{(activities[activeActivity]!== undefined)?activities[activeActivity].title:""}</h1>
                                                    <p className="activity-discription">
                                                        {(activities[activeActivity]!== undefined)?activities[activeActivity].text:""}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8"> {/* */}
                                <img src={API_BASE_URL+'/images/'+ currentImg} style={{width:"90%", height:"80%", marginTop:"3%", borderRadius:"3%"}}></img>
                        </div>


                    </div>


                    <div className='row text-center' style={{width:"100%", marginTop:"5%", marginRight:"0", marginLeft:"0%", backgroundColor:"#F5F5F5"}}>
                        
                            <div className="row">
                                <div className="form-group">
                                    <div className="col">
                                        <label for="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
                                    </div>
                                    <div className="col">
                                        <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group">
                                    <div className="col">
                                        <label for="text" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Text: </label>
                                    </div>
                                    <div className="col">
                                        <textarea style={{marginTop:"1%",  width:"80%"}} type="text" id="text" name="text"></textarea>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="form-group">
                                    <div className="col">
                                        <label for="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Image: </label>
                                    </div>
                                    <div className="col">
                                        <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImagefile(e.target.files)}/>
                                    </div>
                                </div>
                            </div>

                            <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                                <div className="col text=center">
                                    <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddActivity} type="button" className="btn btn-primary">Add Activity</button>
                                </div>
                            </div>

                    
                    </div>
            
            </div>
        
        
        :




        <div className='container-fluid' style={{justifyContent: "center", marginBottom:"5%"}}>
        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
            <div className='col' style={{width:"100%", textAlign:"center", marginTop:"3%"}}>
                <h5 className='activity-how-we-work'>How we work</h5>
            </div>
        </div>
        
        <div className="row" style={{width:"100%", margin:"0", padding:"0"}}>
            <div className='col' style={{width:"100%", textAlign:"center"}}>
                <h1 className='activity-text'>Abu Dhabi malayalee samajam activities</h1>
            </div>
        </div>

        <div className="row" style={{width:"100%", margin:"0", padding:"0", textAlign:"center", justifyContent:"center"}}>
           
            <div className="col-sm-1 activity-left-section d-flex flex-row" style={{width:"10%"}} > {/*className="col-sm-1 text-center" style={{width:"22%",padding:"0", marginRight:"3%"}} */}
                <div className="d-flex activity-mob">
                    {
                        activities.map((activity,index)=>{
                            return(
                                <div className='row' style={{marginBottom:"3%"}}>
                                    <div className="col-sm-1 " style={{width:"40%", height:"80%",margin:"0%", padding:"0", justifyContent:"center", alignContent:"center"}}>
                                        <button onClick={()=>onEditClick(activity.id)} style={{ backgroundColor:"yellow", width:"100%", height:"100%"}}>Edit</button>
                                    </div>
                                    <div className='col-sm-1' style={{width:"50%"}}>
                                        <span className={(activeActivity !== index)? "activity-options-items" : "selected activity-options-items  "} onClick={() => {
                                            setActiveActivity(index);
                                            setCurrentImg(activity.image.path);
                                            }}>
                                            {index+". "}
                                        </span>
                                    </div>
                                </div>
                               
                            )
                        })
                    }   

                </div>


            </div>

            <div className="col-sm-1  activity-right-section" style={{width:"30%"}}> {/* */}
                <h1 className="activity-head">{(activities[activeActivity]!== undefined)? activities[activeActivity].title : ""}</h1>
                <p className="activity-discription">
                {(activities[activeActivity]!== undefined)? activities[activeActivity].text : ""}
                </p>
            </div>

            <div className="col-sm-1" style={{width:"40%"}}> {/* */}
                    <img src={API_BASE_URL+'/images/'+currentImg} style={{width:"90%", height:"80%", marginTop:"3%", borderRadius:"3%"}}></img>
            </div>
            

        </div>

        <div className='row text-center' style={{width:"100%", marginTop:"5%", marginRight:"0", marginLeft:"0%", backgroundColor:"#F5F5F5"}}>
           
                <div className="row">
                    <div className="form-group">
                        <div className="col">
                            <label for="title" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Title: </label>
                        </div>
                        <div className="col">
                            <input style={{marginTop:"1%", width:"30%"}} type="text" id="title" name="title"></input>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group">
                        <div className="col">
                            <label for="text" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Text: </label>
                        </div>
                        <div className="col">
                            <textarea style={{marginTop:"1%",  width:"80%"}} type="text" id="text" name="text"></textarea>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="form-group">
                        <div className="col">
                            <label for="imageFile" style={{marginBottom:"1%", marginRight:"1%", marginTop:"1%"}}>Image: </label>
                        </div>
                        <div className="col">
                            <input style={{marginTop:"1%",}} type="file" id="imageFile" name="imageFile"  onChange={(e)=>setNewImagefile(e.target.files)}/>
                        </div>
                    </div>
                </div>
                <div className="row text-center"style={{marginTop:"1%", marginBottom:"3%", width:"100%"}}>
                    <div className="col text=center">
                        <button style={{marginTop:"1%", width:"25%"}} onClick={handleAddActivity} type="button" className="btn btn-primary">Add Activity</button>
                    </div>
                </div>

        </div>
   
</div>
    )

}

