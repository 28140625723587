// import './homeGalleryy.scss'
// import './homeGalleryy.css'
// import Gallery from "react-photo-gallery";
// import React, { useState, useCallback } from "react";
// import Carousel, { Modal, ModalGateway } from "react-images";
// import { NavLink, useNavigate } from "react-router-dom";
// // import galleryMainImg from '../../Images/galleryMainImg.jpg'
// // import galleryImg1 from '../../Images/galleryImg1.jpg'
// // import galleryImg2 from '../../Images/galleryImg2.jpg'
// // import galleryImg3 from '../../Images/galleryImg3.jpg'
// // import galleryImg4 from '../../Images/galleryImg4.jpg'
// // import MobGallery from '../MobGallery/mobGallery';
// // import galleryImg5 from '../../Images/galleryImg5.jpg'
// // import galleryImg43 from '../../Images/galleryImg43.jpg'
// // import galleryImg7 from '../../Images/galleryImg7.jpg'
// // import galleryImg8 from '../../Images/galleryImg8.jpg'
// // import galleryImg28 from '../../Images/galleryImg28.jpg'
// // import galleryImg10 from '../../Images/galleryImg10.jpg'
// // import galleryImg36 from '../../Images/galleryImg36.jpg'
// // import galleryImg12 from '../../Images/galleryImg18.jpg'
// // import galleryImg21 from '../../Images/galleryImg21.jpg'
// // import galleryImg17 from '../../Images/galleryImg17.jpg'
// // import galleryImg88 from '../../Images/galleryImg88.jpg'
// // import galleryImg22 from '../../Images/galleryImg22.jpg'
// // import galleryImg16 from '../../Images/galleryImg16.jpg'
// // import galleryImg69 from '../../Images/galleryImg69.jpg'
// // import galleryImg61 from '../../Images/galleryImg61.jpg'
// // import galleryImg72 from '../../Images/galleryImg72.jpg'
// // import galleryImg6 from '../../Images/galleryImg6.jpg'
// // import galleryImg14 from '../../Images/galleryImg14.jpg'
// // import galleryImg70 from '../../Images/galleryImg70.jpg'

// /**************************Art ************************* */
// import galleryImg54 from '../../Images/art/galleryImg54.jpg'
// import galleryImg55 from '../../Images/art/galleryImg55.jpg'
// import galleryImg56 from '../../Images/art/galleryImg56.jpg'
// import galleryImg58 from '../../Images/art/galleryImg58.jpg'
// import galleryImg57 from '../../Images/art/galleryImg57.jpg'
// import galleryImg62 from '../../Images/art/galleryImg62.jpg'
// import galleryImg63 from '../../Images/art/galleryImg63.jpg'
// import culturalInviteActivity from '../../Images/art/culturalInviteActivity.jpg'

// /*************************Award************************* */
// import galleryImg19 from '../../Images/award/galleryImg19.jpg';
// import galleryImg21 from '../../Images/award/galleryImg21.jpg'
// import galleryImg23 from '../../Images/award/galleryImg23.jpg'
// import galleryImg25 from '../../Images/award/galleryImg25.jpg'
// import galleryImg26 from '../../Images/award/galleryImg26.jpg'
// import galleryImg27 from '../../Images/award/galleryImg27.jpg'
// import galleryImg28 from '../../Images/award/galleryImg28.jpg'
// import galleryImg33 from '../../Images/award/galleryImg33.jpg'

// /*********************** career Guidance *****************/
// import galleryImg11 from '../../Images/career-guidance/galleryImg11.jpg'
// import galleryImg87 from '../../Images/career-guidance/galleryImg87.jpg'
// import galleryImg88 from '../../Images/career-guidance/galleryImg88.jpg'
// import galleryImg89 from '../../Images/career-guidance/galleryImg89.jpg'
// import seminarsActivity from '../../Images/career-guidance/seminarsActivity.jpg'

// /**********************  Cultural *************************/
// import galleryImg1 from '../../Images/cultural/galleryImg1.jpg'
// import galleryImg17 from '../../Images/cultural/galleryImg17.jpg'
// import galleryImg38 from '../../Images/cultural/galleryImg38.jpg'
// import galleryImg44 from '../../Images/cultural/galleryImg44.jpg'
// import galleryImg47 from '../../Images/cultural/galleryImg47.jpg'
// import galleryImg50 from '../../Images/cultural/galleryImg50.jpg'
// import galleryImg86 from '../../Images/cultural/galleryImg86.jpg'

// /*********************** Iftar **************************** */
// import galleryImg9 from '../../Images/iftar/galleryImg9.jpg'
// import galleryImg43 from '../../Images/iftar/galleryImg43.jpg'
// import galleryImg81 from '../../Images/iftar/galleryImg81.jpg'
// import galleryImg85 from '../../Images/iftar/galleryImg85.jpg'

// /************************ Medical**************************** */
// import galleryImg16 from '../../Images/medical/galleryImg16.jpg'
// import bloodDonationActivity from '../../Images/medical/bloodDonationActivity.jpg'
// import medicalCheckUpActivity from '../../Images/medical/medicalCheckUpActivity.jpg'

// /*********************** Seminar ***************************** */
// import galleryImg52 from '../../Images/seminar/galleryImg52.jpg'
// import galleryImg80 from '../../Images/seminar/galleryImg80.jpg'
// import galleryImg82 from '../../Images/seminar/galleryImg82.jpg'
// import galleryMainImg from '../../Images/seminar/galleryMainImg.jpg'

// /************************ Sport ****************************** */
// import galleryImg7 from '../../Images/sport/galleryImg7.jpg'
// import galleryImg65 from '../../Images/sport/galleryImg65.jpg'
// import galleryImg66 from '../../Images/sport/galleryImg66.jpg'
// import galleryImg67 from '../../Images/sport/galleryImg67.jpg'
// import galleryImg69 from '../../Images/sport/galleryImg69.jpg'
// import galleryImg68 from '../../Images/sport/galleryImg68.jpg'
// import galleryImg70 from '../../Images/sport/galleryImg70.jpg'
// import galleryImg71 from '../../Images/sport/galleryImg71.jpg'
// import galleryImg72 from '../../Images/sport/galleryImg72.jpg'
// import galleryImg73 from '../../Images/sport/galleryImg73.jpg'
// import galleryImg74 from '../../Images/sport/galleryImg74.jpg'
// import galleryImg75 from '../../Images/sport/galleryImg75.jpg'
// import galleryImg76 from '../../Images/sport/galleryImg76.jpg'
// import galleryImg77 from '../../Images/sport/galleryImg77.jpg'
// import galleryImg78 from '../../Images/sport/galleryImg78.jpg'
// import galleryImg79 from '../../Images/sport/galleryImg79.jpg'


// /************************ General ***************************** */
// import galleryImg2 from '../../Images/galleryImg2.jpg'
// import galleryImg3 from '../../Images/galleryImg3.jpg'
// import galleryImg6 from '../../Images/galleryImg6.jpg'
// import galleryImg8 from '../../Images/galleryImg8.jpg'
// import galleryImg10 from '../../Images/galleryImg10.jpg'
// import galleryImg15 from '../../Images/galleryImg15.jpg'
// import galleryImg18 from '../../Images/galleryImg18.jpg'
// import galleryImg20 from '../../Images/galleryImg20.jpg'
// import galleryImg22 from '../../Images/galleryImg22.jpg'
// import galleryImg24 from '../../Images/galleryImg24.jpg'
// import galleryImg29 from '../../Images/galleryImg29.jpg'
// import galleryImg30 from '../../Images/galleryImg30.jpg'
// import galleryImg31 from '../../Images/galleryImg31.jpg'
// import galleryImg32 from '../../Images/galleryImg32.jpg'
// import galleryImg34 from '../../Images/galleryImg34.jpg'
// import galleryImg35 from '../../Images/galleryImg35.jpg'
// import galleryImg36 from '../../Images/galleryImg36.jpg'
// import galleryImg37 from '../../Images/galleryImg37.jpg'
// import galleryImg39 from '../../Images/galleryImg39.jpg'
// import galleryImg40 from '../../Images/galleryImg40.jpg'
// import galleryImg41 from '../../Images/galleryImg41.jpg'
// import galleryImg42 from '../../Images/galleryImg42.jpg'
// import galleryImg45 from '../../Images/galleryImg45.jpg'
// import galleryImg46 from '../../Images/galleryImg46.jpg'
// import galleryImg48 from '../../Images/galleryImg48.jpg'
// import galleryImg49 from '../../Images/galleryImg49.jpg'
// import galleryImg51 from '../../Images/galleryImg51.jpg'
// import galleryImg53 from '../../Images/galleryImg53.jpg'
// import galleryImg59 from '../../Images/galleryImg59.jpg'
// import galleryImg60 from '../../Images/galleryImg60.jpg'
// import galleryImg61 from '../../Images/galleryImg61.jpg'
// import galleryImg64 from '../../Images/galleryImg64.jpg'
// import galleryImg83 from '../../Images/galleryImg83.jpg'
// import galleryImg84 from '../../Images/galleryImg84.jpg'
// import galleryImg90 from '../../Images/galleryImg90.jpg'




































































// import { useEffect } from 'react';
// import { setPagesSession } from '../general-functions/helpers';






// export default function HomeGalleryy() {

//     // const handleShowDialog = () => {
//     //     this.setState({ isOpen: !this.state.isOpen });
//     //     console.log("clicked");
//     //   };

//     // const [currentImage, setCurrentImage] = useState(0);
//     // const [viewerIsOpen, setViewerIsOpen] = useState(false);

//     // const openLightbox = useCallback((event, { photo, index }) => {
//     //     setCurrentImage(index);
//     //     setViewerIsOpen(true);
//     // }, []);

//     // const closeLightbox = () => {
//     //     setCurrentImage(0);
//     //     setViewerIsOpen(false);
//     // };


//     const navigate = useNavigate();
//     const [clickCounts, setClickCounts] = useState(1);// we use it to detect double clicks, since event.detail doesn't work on phones.
//     const [lastCategory, setLastCategory] = useState("");

//     const sport = [galleryImg7, galleryImg65, galleryImg66,galleryImg67, galleryImg68, galleryImg69, galleryImg70, galleryImg71, galleryImg72, galleryImg73, galleryImg74, galleryImg75, galleryImg76, galleryImg77, galleryImg78, galleryImg79, ];
//     const [currentSport, setCurrentSport] = useState(0);

//     const medical = [galleryImg16, bloodDonationActivity, medicalCheckUpActivity];
//     const [currentMedical, setCurrentMedical] = useState(0);

//     const art = [galleryImg54, galleryImg55, galleryImg56, galleryImg57, galleryImg58, galleryImg62, galleryImg63, culturalInviteActivity];
//     const [currentArt, setCurrentArt] = useState(0);

//     const iftarParty = [galleryImg9, galleryImg43, galleryImg81, galleryImg85,];
//     const [currentIftarParty, setCurrentIftarParty] = useState(0);

//     const culturals = [galleryImg1, galleryImg17, galleryImg38, galleryImg47, galleryImg50, galleryImg86];
//     const [currentCulturals, setCurrentCulturals] = useState(0);

//     const seminar = [galleryImg52, galleryImg80, galleryImg82, ];
//     const [currentSeminars, setCurrentSeminars] = useState(0);

//     const awards = [galleryImg19, galleryImg21, galleryImg23, galleryImg25, galleryImg26, galleryImg27, galleryImg28, galleryImg33];
//     const [currentAwards, setCurrentAwards] = useState(0);

//     const careerGuidance = [galleryImg11, galleryImg87, galleryImg88, galleryImg89, seminarsActivity];
//     const [currentCareerGuidance, setCurrentCareerGuidance] = useState(0);


//     const screen1100 = window.visualViewport.width <= 1100 && window.visualViewport.width > 550;
//     const screen550 = window.visualViewport.width<=550;

//     useEffect(()=>{
//         setPagesSession("gallerycategories");
//         // //clear session storage for other pages.
//         // var pages = window.sessionStorage.getItem("pages");
//         // if(Boolean(pages)){
//         //     var pages = JSON.parse(pages);
//         //     var pkeys = Object.getOwnPropertyNames(pages);
//         //     pkeys = pkeys.filter(p=> p != "gallerycategories")
//         //     pkeys.forEach(p => {
//         //         delete pages[p];
//         //     });
//         //     window.sessionStorage.setItem('pages', JSON.stringify(pages));
//         // }
     

//         const intervalId = setInterval(() => {
//             setCurrentSport(Math.floor(Math.random() * sport.length));
//             setCurrentArt(Math.floor(Math.random() * art.length));
//             setCurrentAwards(Math.floor(Math.random() * awards.length));
//             setCurrentCareerGuidance(Math.floor(Math.random() * careerGuidance.length));
//             setCurrentCulturals(Math.floor(Math.random() * culturals.length));
//             setCurrentMedical(Math.floor(Math.random() * medical.length));
//             setCurrentIftarParty(Math.floor(Math.random() * iftarParty.length));
//             setCurrentSeminars(Math.floor(Math.random() * seminar.length));
//         }, 5000)
        
//         return () => clearInterval(intervalId);
//     },[])

//     const imageStyle = {
//         margin: 6,
//         display: 'flex',
//         cursor: 'pointer',
//         width: 250,
//         height: 200,
//         borderRadius: 5,
//     };

//     const photos = [

//         {
//             src: galleryImg1,
//             style: imageStyle

//         },
//         {
//             src: galleryImg2,
//             style: imageStyle

//         },
//         {
//             src: galleryImg21,
//             style: imageStyle

//         },
//         {
//             src: galleryImg28,
//             style: imageStyle

//         },
//         {
//             src: galleryImg88,
//             style: imageStyle

//         },
//         {
//             src: galleryImg22,
//             style: imageStyle

//         },
//         {
//             src: galleryImg16,
//             style: imageStyle

//         },
//         {
//             src: galleryImg69,
//             style: imageStyle

//         },
//         {
//             src: galleryImg43,
//             style: imageStyle

//         },
//         {
//             src: galleryImg10,
//             style: imageStyle

//         },
//         {
//             src: galleryImg36,
//             style: imageStyle

//         },
//         {
//             src: galleryImg17,
//             style: imageStyle

//         },
       
//     ];


//     // return (
//     //     <>

//     //         <div className=' mb-5'>
//     //             <h1 className='gallery-title '>our <span className='head-design'>gallery</span> </h1>
//     //             <MobGallery />
//     //             <div id="logos-home-page" className='gallery-contents'>
//     //                 <div class="slider">
//     //                     <div class="slide-track">
//     //                         <div className="row gallery-style">
//     //                             <div className="col-lg-6 col-md-6 slide gallery-slide-img" >
//     //                                 <img  className='gallery-main-img' src={galleryMainImg} onClick={handleShowDialog} onClose={closeLightbox} alt="" />
//     //                             </div>
//     //                             <div className="col-lg-6 col-md-6 slide gallery-slide-img">
//     //                                 <Gallery photos={photos} onClick={openLightbox} />
//     //                                 <ModalGateway>
//     //                                     {viewerIsOpen ? (
//     //                                         <Modal onClose={closeLightbox}>
//     //                                             <Carousel

//     //                                                 currentIndex={currentImage}
//     //                                                 views={photos.map(x => ({
//     //                                                     ...x,
//     //                                                     srcset: x.srcSet,
//     //                                                     caption: x.title
//     //                                                 }))}
//     //                                             />
//     //                                         </Modal>
//     //                                     ) : null}
//     //                                 </ModalGateway>

//     //                             </div>
//     //                         </div>


//     //                     </div>
//     //                 </div>
//     //             </div>
//     //             <div className='align-self-center mx-auto'>


//     //                 <NavLink className="gallery-btn-txt" to="/gallery">
//     //                     <button type="button" class="btn gallery-btn ">
//     //                         load <span className='title-design'>more</span>
//     //                     </button>
//     //                 </NavLink>


//     //                 {/* <button type="button"  class="btn gallery-btn ">load <span className='title-design'>more</span></button> */}

//     //             </div>
//     //         </div>
//     //     </>
//     // )


//     const onLoadMore = ()=>{
//         var pages =  window.sessionStorage.getItem('pages');
        
//         pages = JSON.parse(pages);
//         pages = {...pages, galleryimages:{category: "general"}};
//         window.sessionStorage.setItem('pages', JSON.stringify(pages));
            
//         navigate('/gallery/images');
//     }

//     const navigateToViewImages = (category)=>{
//         if(screen550 || screen1100){
//             console.log("lastCategory: ", lastCategory);
//             setLastCategory(category)

//             if(category == lastCategory){
//                var pages =  JSON.parse(window.sessionStorage.getItem('pages'));
//                pages = {...pages, galleryimages:{category: category}};
//                 window.sessionStorage.setItem('pages', JSON.stringify(pages));
                
//                 navigate('/gallery/images');
//             }
//         }   
//         else{
//             var pages =  window.sessionStorage.getItem('pages');
        
//             pages = JSON.parse(pages);
//             pages = {...pages, galleryimages:{category: category}};
//             window.sessionStorage.setItem('pages', JSON.stringify(pages));
                
//             navigate('/gallery/images');
            
          
//         }
        
//     }





//     return (


//         (screen550)?



//         <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
//         <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
//             <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
//                 <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
//             </div>
//         </div>

//         <div className="row" style={{width:"100%", marginBottom:"5%", padding:"0", marginLeft:"0", marginRight:"0"}}>
            
//             <div className="row" style={{width:"78%", height: (200*window.visualViewport.width/550)+'px', marginLeft:"11%", marginRight:"11%", padding:"0",}}>
//                 {/* The Big Image */}
//                 <img style={{width:"100%", height:"100%", margin:"0", borderRadius: "4%"}}
                    
//                     src={galleryImg10}
//                     alt="First slide"
//                 />
                
                    
//             </div>

//             <div className="row" style={{width:"78%", marginLeft:"11%", marginRight:"11%", marginBottom:"5%",  padding:"0", marginTop:"3%" }}>
//                 {/*Image Categories*/}
//                 <div className='row' style={{height:(100*window.visualViewport.width/550)+'px',width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                         <img className='image' src={culturals[currentCulturals]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Culturals")} class="text">Culturals</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                         <img className='image' src={medical[currentMedical]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Medicals")} class="text">Medicals</div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className='row' style={{height:(100*window.visualViewport.width/550)+'px',width:"100%", marginBottom:"5%",marginLeft:"0", marginRight:"0" }}>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                         <img className='image' src={seminar[currentSeminars]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Seminars")} class="text">Seminars</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                         <img className='image' src={awards[currentAwards]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Awards")}  class="text">Awards</div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className='row' style={{height:(100*window.visualViewport.width/550)+'px',width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                         <img className='image' src={art[currentArt]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Art")} class="text">Art</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                         <img className='image' src={sport[currentSport]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Sport")} class="text">Sport</div>
//                         </div>
//                     </div>
//                 </div>

//                 <div className='row' style={{height:(100*window.visualViewport.width/550)+'px',width:"100%", marginLeft:"0", marginRight:"0"}}>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                         <img className='image' src={iftarParty[currentIftarParty]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Iftar Parties")} class="text">Iftar Parties</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                         <img className='image' src={careerGuidance[currentCareerGuidance]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Career Guidance")} class="text">Career Guidance</div>
//                         </div>
//                     </div>
//                 </div>

                
//                 <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"3%", padding:"0"}}>
                  
//                   <div className="gallery-btn-txt" to="/about"  style={{marginLeft:"5%", marginRight:"5%"}}>
//                       <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>{/*className="btn about-home-btn*/}
//                           Load <span className='title-design'>more</span>
//                       </button>
//                   </div>
//               </div>

//             </div>

//         </div>
//     </div>






//         :


//         (screen1100)?

//         <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
//             <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
//                 <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
//                     <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
//                 </div>
//             </div>

//             <div className="row" style={{width:"100%", marginBottom:"5%", padding:"0", marginLeft:"0", marginRight:"0"}}>
                
//                 <div className="row" style={{width:"78%", height:"400px", marginLeft:"11%", marginRight:"11%", padding:"0",}}>
//                     {/* The Big Image */}
//                     <img style={{width:"100%", height:"100%", margin:"0", borderRadius: "4%"}}
                        
//                         src={galleryImg10}
//                         alt="First slide"
//                     />
                    
                        
//                 </div>

//                 <div className="row" style={{width:"78%", marginLeft:"11%", marginRight:"11%", marginBottom:"5%",  padding:"0", marginTop:"3%" }}>
//                     {/*Image Categories*/}
//                     <div className='row' style={{height:"200px",width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                             <img className='image' src={culturals[currentCulturals]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Culturals")} class="text">Culturals</div>
//                             </div>
//                         </div>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                             <img className='image' src={medical[currentMedical]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Medicals")} class="text">Medicals</div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className='row' style={{height:"200px",width:"100%", marginBottom:"5%",marginLeft:"0", marginRight:"0" }}>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                             <img className='image' src={seminar[currentSeminars]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Seminars")}  class="text">Seminars</div>
//                             </div>
//                         </div>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                             <img className='image' src={awards[currentAwards]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Awards")} class="text">Awards</div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className='row' style={{height:"200px",width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                             <img className='image' src={art[currentArt]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Art")} class="text">Art</div>
//                             </div>
//                         </div>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                             <img className='image' src={sport[currentSport]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Sport")} class="text">Sport</div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className='row' style={{height:"200px",width:"100%", marginLeft:"0", marginRight:"0"}}>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
//                             <img className='image' src={iftarParty[currentIftarParty]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Iftar Parties")} Sport class="text">Iftar Parties</div>
//                             </div>
//                         </div>
//                         <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
//                             <img className='image' src={careerGuidance[currentCareerGuidance]} alt="First slide"/>
//                             <div class="middle">
//                                 <div onClick={()=>navigateToViewImages("Career Guidance")} class="text">Career Guidance</div>
//                             </div>
//                         </div>
//                     </div>

//                     <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"3%",}}>
                  
//                         <div className="gallery-btn-txt" to="/about"  style={{paddingLeft:"5%", paddingRight:"5%"}}>
//                             <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>{/*className="btn about-home-btn*/}
//                                 Load <span>more</span>
//                             </button>
//                         </div>
//                     </div>

//                 </div>
//             </div>
//         </div>




//         :







//         <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
//         <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
//             <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
//                 <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
//             </div>
//         </div>

//         <div className="row" style={{width:"100%", height:"70%", marginBottom:"5%", padding:"0"}}>
            
//             <div className="col-sm-1" style={{width:"30%", height:"400px", marginLeft:"2%", marginRight:"3%", padding:"0",}}>
//                 {/* The Big Image */}
//                     <img style={{width:"100%", height:"100%", margin:"0", padding:"0", borderRadius: "3%"}}
                       
//                        src={galleryImg10}
//                        alt="First slide"
//                     />
                
                    
//             </div>

//             <div className="col-sm-1" style={{width:"63%", height:"400px", marginLeft:"1%", marginRight:"1%" }}>
//                 {/*Image Categories*/}
//                 <div className='row' style={{height:"190px",width:"100%", marginBottom:"20px" }}>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={culturals[currentCulturals]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Culturals")} class="text">Culturals</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={medical[currentMedical]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Medicals")} class="text">Medicals</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={seminar[currentSeminars]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Seminars")} class="text">Seminars</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={awards[currentAwards]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Awards")} class="text">Awards</div>
//                         </div>
//                     </div>

                    
//                 </div>

//                 <div className='row' style={{height:"190px",width:"100%", }}>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={art[currentArt]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Art")} class="text">Art</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={sport[currentSport]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Sport")} class="text">Sport</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={iftarParty[currentIftarParty]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Iftar Parties")} class="text">Iftar Parties</div>
//                         </div>
//                     </div>
//                     <div className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
//                         <img className='image' src={careerGuidance[currentCareerGuidance]} alt="First slide"/>
//                         <div class="middle">
//                             <div onClick={()=>navigateToViewImages("Career Guidance")} class="text">Career Guidance</div>
//                         </div>
//                     </div>

                    
//                 </div>
                
//                 {/* <div className="row" style={{marginTop: "1%"}}>
//                     <div className="col text-center">
//                         <button onClick={()=>navigateToViewImages("general")} style={{padding:"0.5%"}}>Load More</button>
//                     </div>
//                 </div> */}

//                 <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"1%", marginRight:"0",}}>
                  
//                   <div className="gallery-btn-txt" to="/about"  style={{width:"100%", padding:"0"}}>
//                       <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>{/*className="btn about-home-btn*/}
//                           Load <span className='title-design'>more</span>
//                       </button>
//                   </div>
//               </div>

//             </div>
//         </div>
//     </div>


//     )

// }




























import './homeGalleryy.scss'
import './homeGalleryy.css'
import Gallery from "react-photo-gallery";
import React, { useState, useCallback } from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { NavLink, useNavigate } from "react-router-dom";



import { getCategories } from '../../services/images'; 
import { useEffect } from 'react';


import galleryImg10 from '../../Images/galleryImg10.jpg';
import { API_BASE_URL, IMAGES_URL } from '../../constants';

const ImageCategories = ({setLoading})=>{



    const navigate = useNavigate();
    const [clickCounts, setClickCounts] = useState(1);// we use it to detect double clicks, since event.detail doesn't work on phones.
    const [lastCategory, setLastCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [currentCategoryImages, setCurrentCategoryImages] = useState(null);

    const [imgsCount, setImgsCount] = useState(0);
    var readyImgs = 0;


    // const sport = [galleryImg7, galleryImg65, galleryImg66,galleryImg67, galleryImg68, galleryImg69, galleryImg70, galleryImg71, galleryImg72, galleryImg73, galleryImg74, galleryImg75, galleryImg76, galleryImg77, galleryImg78, galleryImg79, ];
    // const [currentSport, setCurrentSport] = useState(0);

    // const medical = [galleryImg16, bloodDonationActivity, medicalCheckUpActivity];
    // const [currentMedical, setCurrentMedical] = useState(0);

    // const art = [galleryImg54, galleryImg55, galleryImg56, galleryImg57, galleryImg58, galleryImg62, galleryImg63, culturalInviteActivity];
    // const [currentArt, setCurrentArt] = useState(0);

    // const iftarParty = [galleryImg9, galleryImg43, galleryImg81, galleryImg85,];
    // const [currentIftarParty, setCurrentIftarParty] = useState(0);

    // const culturals = [galleryImg1, galleryImg17, galleryImg38, galleryImg47, galleryImg50, galleryImg86];
    // const [currentCulturals, setCurrentCulturals] = useState(0);

    // const seminar = [galleryImg52, galleryImg80, galleryImg82, ];
    // const [currentSeminars, setCurrentSeminars] = useState(0);

    // const awards = [galleryImg19, galleryImg21, galleryImg23, galleryImg25, galleryImg26, galleryImg27, galleryImg28, galleryImg33];
    // const [currentAwards, setCurrentAwards] = useState(0);

    // const careerGuidance = [galleryImg11, galleryImg87, galleryImg88, galleryImg89, seminarsActivity];
    // const [currentCareerGuidance, setCurrentCareerGuidance] = useState(0);

    const screen1100 = window.visualViewport.width <= 1100 && window.visualViewport.width > 550;
    const screen550 = window.visualViewport.width<=550;
    

    useEffect(()=>{
       
        getCategories().then(response =>{
            if(response.status == 200){
                setCategories(response.data.data);
                setImgsCount(response.data.data.length);
                if(response.data.data.length == 0) setLoading(false);
                // if (setLoading !== undefined) setLoading(false);
            }
            else{
                // alert("couldn't load images");
            }
        }).catch(err=>{
            // alert("couldn't load images");
            console.error(err);
        })

       
        
    },[])

    useEffect(()=>{

        if(categories.length > 0){
            console.log("currentCategoryImagesClone: ", currentCategoryImages);
            const intervalId = setInterval(() => {
                var currentCategoryImagesClone = {}
                categories.forEach(c=>{
                    currentCategoryImagesClone[c.name] = Math.floor(Math.random() * c.images.length);
                })
                console.log("interval: currentCategoryImagesClone: ", currentCategoryImagesClone);
                setCurrentCategoryImages(currentCategoryImagesClone);
            }, 5000);

            return () => clearInterval(intervalId);

        }
    },[categories])

    
    

    const imageStyle = {
        margin: 6,
        display: 'flex',
        cursor: 'pointer',
        width: 250,
        height: 200,
        borderRadius: 5,
    };


    // const onLoadMore = ()=>{
    //     var pages =  window.sessionStorage.getItem('pages');
        
    //     pages = JSON.parse(pages);
    //     pages = {...pages, galleryimages:{category: "general"}};
    //     window.sessionStorage.setItem('pages', JSON.stringify(pages));
            
    //     navigate('/gallery/images');
    // }

    const navigateToViewImages = (category)=>{
        if(screen550 || screen1100){
        //    setLastCategory(category)
           // if(category == lastCategory){
               var pages =  JSON.parse(window.sessionStorage.getItem('pages'));
               pages = {...pages, galleryimages:{category: category}};
                window.sessionStorage.setItem('pages', JSON.stringify(pages));
                
                navigate('/galleryimages');
           // }
        }   
        else{
            var pages =  window.sessionStorage.getItem('pages');
        
            pages = JSON.parse(pages);
            pages = {...pages, galleryimages:{category: category}};
            window.sessionStorage.setItem('pages', JSON.stringify(pages));
                
            navigate('/galleryimages');
            
          
        }
        
    }

    function onImgLoad(){
        if(setLoading != undefined){ 
            readyImgs+=1;
            if(readyImgs == imgsCount){
                setLoading(false)
            }
        }
    }
    

    function Screen550Categories(){
        var categories550 = [];
        for(var i = 0 ; i < categories.length; i++){
            var item = [categories[i]];
            if(categories[i+1] != undefined){
                item.push(categories[i+1]);
                i++;
            }
            categories550.push(item);
        }
        
        return(
        <div className="row" style={{width:"78%", marginLeft:"11%", marginRight:"11%", marginBottom:"5%",  padding:"0", marginTop:"3%" }}>
            { 
            categories550.map((item, index)=>{
                return (
                    <div key={index} className='row' style={{height:(100*window.visualViewport.width/550)+'px', width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
                        <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
                            <img onLoad={onImgLoad} className='image' src={IMAGES_URL+'/'+ item[0].name +'/'+item[0].images[(currentCategoryImages == null)? 0 : currentCategoryImages[item[0].name]].path} alt="First slide"/>
                            <div class="middle">
                                <div onClick={()=>navigateToViewImages(item[0].name)} class="text">{item[0].name}</div>
                            </div>
                         
                        </div>
                        {
                            (item[1] != undefined)?
                                <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
                                    <img onLoad={onImgLoad} className='image' src={IMAGES_URL+'/'+ item[1].name +'/'+ item[1].images[(currentCategoryImages == null)? 0 :currentCategoryImages[item[1].name]].path} alt="First slide"/>
                                    <div class="middle">
                                        <div onClick={()=>navigateToViewImages(item[1].name)} class="text">{item[1].name}</div>
                                    </div>

                                </div>
                            :
                                <></>
                        }
                    </div>
                )
            })
                
            }

            {/* <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"3%",}}>         
                <div className="gallery-btn-txt" to="/about"  style={{paddingLeft:"5%", paddingRight:"5%"}}>
                    <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>
                        Load <span>more</span>
                    </button>
                </div>
            </div> */}

            
        </div>
        )
    }


    function Screen1100Categories(){

        var categories1100 = [];
        for(var i = 0 ; i < categories.length; i++){
            var item = [categories[i]];
            if(categories[i+1] != undefined){
                item.push(categories[i+1]);
                i++;
            }
            categories1100.push(item);
        }

        
        return (
            
            <div className="row" style={{width:"78%", marginLeft:"11%", marginRight:"11%", marginBottom:"5%",  padding:"0", marginTop:"3%" }}>
               {
                categories1100.map((item, index)=>{
                    return(
                        <div key={index} className='row' style={{height:"200px",width:"100%", marginBottom:"5%", marginLeft:"0", marginRight:"0" }}>
                            <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", marginRight:"5%", padding:"0", position:"relative"}}>
                                <img onLoad={onImgLoad} className='image' src={IMAGES_URL+'/'+ item[0].name +'/'+item[0].images[(currentCategoryImages==null)? 0 : currentCategoryImages[item[0].name]].path} alt="First slide"/>
                                <div class="middle">
                                    <div onClick={()=>navigateToViewImages(item[0].name)} class="text">{item[0].name}</div>
                                </div>

                            </div>
                            {
                                (item[1] != undefined)?
                                    <div className="col-sm-1 img-container" style={{width:"47.5%", height:"100%", padding:"0", position:"relative"}}>
                                        <img onLoad={onImgLoad} className='image' src={IMAGES_URL+'/'+ item[1].name +'/'+ item[1].images[(currentCategoryImages==null)? 0 : currentCategoryImages[item[1].name]].path} alt="First slide"/>
                                        <div class="middle">
                                            <div onClick={()=>navigateToViewImages(item[1].name)} class="text">{item[1].name}</div>
                                        </div>

                                    </div>
                                :
                                    <></>
                            }
                        </div>
                    )
                })
               }
                {/* <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"3%",}}>         
                    <div className="gallery-btn-txt" to="/about"  style={{paddingLeft:"5%", paddingRight:"5%"}}>
                        <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>
                            Load <span>more</span>
                        </button>
                    </div>
                </div> */}
               
                
            </div>

          
            
        )
    }




    function WideScreenCategories(){

        var categoriesWideScreen = [];
        for(var i = 0 ; i < categories.length; i++){
            var item = [categories[i]];
            for(var j = 1 ; j <=3; j++){
                if(categories[i+1] != undefined){
                    item.push(categories[i+1]);
                    i++;
                }
                else{
                    break;
                }
            }

            categoriesWideScreen.push(item);
        }
       
    
        return(
            <div className="col-sm-1" style={{width:"63%", marginLeft:"1%", marginRight:"1%" }}>
            {
                categoriesWideScreen.map(item=>{
                    return(
                        <div className='row' style={{height:"190px",width:"100%", marginBottom:"20px" }}>
                            {
                                item.map((c, index)=>{
                                    return(
                                    //    (c.images.length>0)? is images got empty, an error will be raised
                                        <div key={index} className="col-sm-1 img-container" style={{width:"24%", height:"100%", marginRight:"1%", padding:"0", position:"relative"}}>
                                            <img className='image' onLoad={onImgLoad} src={IMAGES_URL+'/'+ c.name +'/'+ c.images[(currentCategoryImages==null)? 0 : currentCategoryImages[c.name] ].path} alt="First slide"/>
                                            <div class="middle">
                                                <div onClick={()=>navigateToViewImages(c.name)} class="text">{c.name}</div>
                                            </div>
                                        </div>
                                    //    :<></>
                                        
                                    )
                                })
                            }      
                    </div>
                    )
                })
               
            }

            {/* <div className='row text-center' style={{width:"100%", marginBottom:"3%", marginTop:"1%", marginRight:"0",}}>
                <div className="gallery-btn-txt" to="/about"  style={{width:"100%", padding:"0"}}>
                    <button type="button" className="btn gallery-btn" onClick={()=>onLoadMore()}>
                        Load <span className='title-design'>more</span>
                    </button>
                </div>
            </div> */}


        </div>

            
        )
    }












    return (
        
        
        (screen550)?
      

        <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
        <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
            <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
                <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
            </div>
        </div>

        <div className="row" style={{width:"100%", marginBottom:"5%", padding:"0", marginLeft:"0", marginRight:"0"}}>
            
            <div className="row" style={{width:"78%", height: (200*window.visualViewport.width/550)+'px', marginLeft:"11%", marginRight:"11%", padding:"0",}}>
                {/* The Big Image */}
                <img style={{width:"100%", height:"100%", margin:"0", borderRadius: "4%"}}
                    
                    src={galleryImg10}
                    alt="First slide"
                />
                    
            </div>
           
            <Screen550Categories />

        </div>
    </div>






        :


        (screen1100)?

        <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
            <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
                <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
                    <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
                </div>
            </div>

            <div className="row" style={{width:"100%", marginBottom:"5%", padding:"0", marginLeft:"0", marginRight:"0"}}>
                
                <div className="row" style={{width:"78%", height:"400px", marginLeft:"11%", marginRight:"11%", padding:"0",}}>
                    {/* The Big Image */}
                    <img  style={{width:"100%", height:"100%", margin:"0", borderRadius: "4%"}}
                        
                        src={galleryImg10}
                        alt="First slide"
                    />
                    
                        
                </div>

                <Screen1100Categories />
            </div>
        </div>




        :







        <div className="container-fluid" style={{width:"100%", margin:"0", padding:"0",}}>
        <div className="row" style={{width:"100%", height:"30%", margin:"0", padding:"0"}}>
            <div className="col-sm-1" style={{width:"100%", margin:"0", padding:"0"}}>
                <h1 className='gallery-title '><span className='head-design'>Gallery</span> </h1>
            </div>
        </div>

        <div className="row" style={{width:"100%", height:"70%", marginBottom:"5%", padding:"0"}}>
            
            <div className="col-sm-1" style={{width:"30%", height:"400px", marginLeft:"2%", marginRight:"3%", padding:"0",}}>
                {/* The Big Image */}
                    <img style={{width:"100%", height:"100%", margin:"0", padding:"0", borderRadius: "3%"}}
                       
                       src={galleryImg10}
                       alt="First slide"
                    />

            </div>

            <WideScreenCategories />

        </div>
    </div>


    )

}


    


export default ImageCategories;