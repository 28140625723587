import './App.css';
import Home from './Pages/Home/home';
import Navbar from './Components/Navbar/navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Footer from './Components/Footer/footer';
import FootBar from './Components/FootBar/footBar';
import About from './Pages/About/about';
import Facility from './Pages/Facility/facility';
import Event from './Pages/Event/event';
import Management from './Pages/Management/management';
import Galleryy from './Pages/Gallery/gallery';
import Contact from './Pages/Contact/contact'
import WhatssApp from './Components/WhatssApp/whatssApp';
import ImageCategories from './Pages/Gallery/ImageCategories';
import Gallery from './Pages/Gallery';
import ViewImages from './Pages/Gallery/ViewImages';
import GalleryDashboard from './Pages/Dashboard/GalleryDashboard';
import ImageCategoriesDashboard from './Pages/Dashboard/GalleryDashboard/ImageCategoriesDashboard';
import ViewImagesDashboard from './Pages/Dashboard/GalleryDashboard/ViewImagesDashboard';
import AddImageCategory from './Pages/Dashboard/GalleryDashboard/AddImageCategory';
import ActivitiesDashoard from './Pages/Dashboard/activities/ActivitiesDashboard';
import EventsDashboard from './Pages/Dashboard/events/EventsDashboard';
import EditActivity from './Pages/Dashboard/activities/EditActivity';
import EditEvent from './Pages/Dashboard/events/EditEvent';
// import Logo from './Images/Logo.jpg'
// import { useState } from 'react';

{/*
function remove() {
  if (location === "contact.js")
  {
    ('.searchbar').hide();
   
  }
}*/}




function App() {

  // const [loading, setLoading] = useState(true);
  return (
    <>
    {/* <div hidden={!loading} className="container-fluid" style={{width:"100vw", height:"100vh",}}>
            <div className="row text-center">
                <div className="col my-auto text-center" style={{width:"100%", height:"100%", }}>
                   

                 
                    <img
                        src={Logo}
                        style={{width:"200px", height:"200px", borderRadius:"50%" , marginTop:"25vh"}} 
                    />
                </div>
            </div>
        </div> */}

    {/* <div hidden = {loading} onLoad={()=>{setLoading(false)}}> */}
    <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
           {/* <Route path='/facility' element={<Facility />}></Route>  */}
          {/* <Route path='/gallery' element={<Gallery />}></Route><Galleryy /> */}
          <Route path='/news&events' element={<Event />}></Route>
          <Route path='/management' element={<Management />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path={'/gallerycategories'} element={<ImageCategories />} ></Route>
          <Route path={`/galleryimages`} element={<ViewImages />} />
          <Route path={`/dashboard/gallery`} element={<GalleryDashboard />} />
          <Route path={`/dashboard/gallery/categories`} element={<ImageCategoriesDashboard />} />
          <Route path={`/dashboard/gallery/images`} element={<ViewImagesDashboard />} />
          <Route path={'/dashboard/gallery/categories/add'} element={<AddImageCategory />} />
          <Route path={'/dashboard/activities'} element={<ActivitiesDashoard />} />
          <Route path={'/dashboard/activities/edit'} element={<EditActivity />} />
          <Route path={'/dashboard/events/edit'} element={<EditEvent />} />


          <Route path={'/dashboard/events'} element={<EventsDashboard />} />


         
        </Routes>
        <WhatssApp />
        <Footer />
        <FootBar />
      </Router>

    {/* </div> */}
      


    </>
  );
}

export default App;
