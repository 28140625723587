import { useEffect } from 'react';
import { setPagesSession } from '../../Components/general-functions/helpers';
import ManagementSection from '../../Components/MangementSection/ManagementSection'




export default function Management() {
    useEffect(()=>{
        setPagesSession("management");//this will remove all other pages values from the key "pages" (which is a stringified json object) of our session storage.
      },[])

    return (
        <>
            <div >
                <ManagementSection />
              
            </div>
        </>
    )
}