import React, { useEffect } from 'react';
import {
  MDBFooter,
  MDBCol,
  MDBRow,
  MDBIcon
} from 'mdb-react-ui-kit';
import cmpLogo from "../../Images/Logo.jpg";
import './footer.css'
import { useState } from 'react';
import { getField } from '../../services/general-fields';

export default function Footer() {
  // return (
  //   <MDBFooter className='bg-img text-center text-white'>
  //     <div className=' p-4 pb-0 mt-5 mb-5'>
  //       <div className="row">
  //         <div className="col-lg-4">
  //           <div>
  //             <a href="">
  //               <img className='comp-logo' src={cmpLogo} alt="" />
  //             </a>
  //             <p className='footer-discription'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi nostrum explicabo, sunt iure fuga repudiandae atque non dolorem nesciunt alias nam pariatur odio incidunt recusandae tenetur qui animi aperiam odit.</p>
  //           </div>
  //           <section className='mb-4'>
  //             <a
  //               className='btn btn-primary btn-floating m-1'
  //               style={{ backgroundColor: '#3b5998' }}
  //               href='https://www.facebook.com/MalayaleeSamajamAUH/'
  //               role='button'
  //             >
  //               <MDBIcon fab icon='facebook-f' />
  //             </a>

  //             <a
  //               className='btn btn-primary btn-floating m-1'
  //               style={{ backgroundColor: '#55acee' }}
  //               href='/'
  //               role='button'
  //             >
  //               <MDBIcon fab icon='twitter' />
  //             </a>

  //             <a
  //               className='btn btn-primary btn-floating m-1'
  //               style={{ backgroundColor: '#dd4b39' }}
  //               href='https://www.google.com/search?q=Abu+Dhabi+Malayalee+Samajam&rlz=1C1SQJL_enAE877AE877&tbm=lcl&sxsrf=ALiCzsZZUADGrCjmb3y5iFxe7kfAxGt7PQ%3A1655717907492&ei=E0CwYuvRHfiG4dUPhIWBuAw&oq=Abu+Dhabi+Malayalee+Samajam&gs_l=psy-ab.12...0.0.0.8350.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.rh_WnVn_v5I#rlfi=hd:;si:17508858421805708102,l,ChtBYnUgRGhhYmkgTWFsYXlhbGVlIFNhbWFqYW1I4OWUj82CgIAIWi0QAhADGAAYARgCGAMiG2FidSBkaGFiaSBtYWxheWFsZWUgc2FtYWphbSoCCAKSARxzb2NpYWxfc2VydmljZXNfb3JnYW5pemF0aW9u;mv:[[24.350298477319022,54.514183050858044],[24.349938522680965,54.513787949141964]]'
  //               role='button'
  //             >
  //               <MDBIcon fab icon='google' />
  //             </a>
  //             <a
  //               className='btn btn-primary btn-floating m-1'
  //               style={{ backgroundColor: '#ac2bac' }}
  //               href='/'
  //               role='button'
  //             >
  //               <MDBIcon fab icon='instagram' />
  //             </a>

              
  //           </section>
  //         </div>
  //         <div className="col-lg-4">
  //           <form action='' className='mt-5'>
  //             <MDBRow className='d-flex justify-content-center'>
  //               <MDBCol size='auto' className='mb-4 mb-md-0'>
  //                 <p className='footer-signup pt-2'>
  //                   <strong>Sign up for our newsletter</strong>
  //                 </p><br />
  //                 <div class="custom-search">
  //                 <input required type="email" class="custom-search-input" placeholder="Enter your email" />
  //                 <button class="custom-search-botton" type="submit">Subscribe</button>

                
  //               </div>
             
  //               </MDBCol>

                
  //             </MDBRow>
            
  //           </form>
  //         </div>
  //         <div className="col-lg-4 iframe">
  //           <iframe
  //             title='mapMarker'
  //             src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3634.896579431055!2d54.51179811537016!3d24.35009497151797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e40b6e1bedc4f%3A0xf2fbf5f7fe55f346!2sAbu%20Dhabi%20Malayalee%20Samajam!5e0!3m2!1sen!2sae!4v1655274559763!5m2!1sen!2sae'
  //             width='100%'
  //             height='100%'
  //             frameBorder='0'
  //             allowFullScreen
  //           />
  //         </div>
  //       </div>

  //     </div>


  //   </MDBFooter>
  // );




  const [footerDescription, setFooterDescription] = useState('');


  const mapHeight = (400*window.visualViewport.width/1000);
  const screen1000 = window.visualViewport.width<=1000;

  useEffect(()=>{
    getField('Footer Description').then(response=>{
      setFooterDescription(response.data.data.value);
    }).catch(err=>{
      console.log(err);
      // alert("couldn't load the footer description");
    })
  },[])

  return (


    (screen1000)?
    <MDBFooter className='bg-img text-center text-white'> {/*className='bg-img text-center text-white*/}
      <div className=' p-4 pb-0 mt-5 mb-5'> {/*className=' p-4 pb-0 mt-5 mb-5' */}
        <div className="row text-center">
            <div>
              <a href="">
                <img className='comp-logo' src={cmpLogo} alt="" />
              </a>
              <p className='footer-discription'>{footerDescription}</p>
              </div>{/*Abu Dhabi Malayalee Samajam was established in 1969, as a leading socio- cultural forum for Keralites living in Abu Dhabi that spreads the broad spectrum of the society. Connect our members and be part of the cultural and social activities to nourish the rich diversity of Kerala. We are devoted to fabricate an environment conducive in fostering and elevating the heritage of Kerala in the land of UAE. We entices key values that needs to be build Kerala’s traits and encourage collectivenes. */}
            <section className='mb-4'>
              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#3b5998' }}
                href='https://www.facebook.com/MalayaleeSamajamAUH/'
                role='button'
              >
                <MDBIcon fab icon='facebook-f' />
              </a>

              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#55acee', }}
                href='/'
                role='button'
              >
                <MDBIcon fab icon='twitter' />
              </a>

              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#dd4b39' }}
                href='https://www.google.com/search?q=Abu+Dhabi+Malayalee+Samajam&rlz=1C1SQJL_enAE877AE877&tbm=lcl&sxsrf=ALiCzsZZUADGrCjmb3y5iFxe7kfAxGt7PQ%3A1655717907492&ei=E0CwYuvRHfiG4dUPhIWBuAw&oq=Abu+Dhabi+Malayalee+Samajam&gs_l=psy-ab.12...0.0.0.8350.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.rh_WnVn_v5I#rlfi=hd:;si:17508858421805708102,l,ChtBYnUgRGhhYmkgTWFsYXlhbGVlIFNhbWFqYW1I4OWUj82CgIAIWi0QAhADGAAYARgCGAMiG2FidSBkaGFiaSBtYWxheWFsZWUgc2FtYWphbSoCCAKSARxzb2NpYWxfc2VydmljZXNfb3JnYW5pemF0aW9u;mv:[[24.350298477319022,54.514183050858044],[24.349938522680965,54.513787949141964]]'
                role='button'
              >
                <MDBIcon fab icon='google' />
              </a>
              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#ac2bac' }}
                href='/'
                role='button'
              >
                <MDBIcon fab icon='instagram' />
              </a>

              
            </section>
          </div>
          {/* <div className="col-lg-4">               hide until the backend functionality is done
            <form action='' className='mt-5'>
              <MDBRow className='d-flex justify-content-center'>
                <MDBCol size='auto' className='mb-4 mb-md-0'>
                  <p className='footer-signup pt-2'>
                    <strong>Sign up for our newsletter</strong>
                  </p><br />
                  <div class="custom-search">
                  <input style={{width:"100%"}} required type="email" class="custom-search-input" placeholder="Enter your email" />
                  <button class="custom-search-botton" type="submit">Subscribe</button>

                
                </div>
             
                </MDBCol>

                
              </MDBRow>
            
            </form>
          </div> */}
          <div className="row text-center" style={{border:"10px solid gray", padding:"0"} }>
             {/*style={{height: mapHeight+'px', width:mapHeight*1.5+"px"}} */}
            <iframe style={{margin:"0", padding:"0"}}
              title='mapMarker'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3634.896579431055!2d54.51179811537016!3d24.35009497151797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e40b6e1bedc4f%3A0xf2fbf5f7fe55f346!2sAbu%20Dhabi%20Malayalee%20Samajam!5e0!3m2!1sen!2sae!4v1655274559763!5m2!1sen!2sae'
              // width='100%'
              // height='100%'
              height = {mapHeight+'px'}
              width = {mapHeight*1.5 + 'px'}
              frameBorder='0'
              allowFullScreen
            />
          </div>
        </div>



    </MDBFooter>






    :

     <MDBFooter className='bg-img text-center text-white'> {/*className='bg-img text-center text-white*/}
      <div className=' p-4 pb-0 mt-5 mb-5'> {/*className=' p-4 pb-0 mt-5 mb-5' */}
        <div className="row">
          <div className="col-lg-5" style={{width:"48%", marginRight:"15%"}}>
            <div>
              <a href="">
                <img className='comp-logo' src={cmpLogo} alt="" />
              </a>
              <p className='footer-discription'>{footerDescription}</p>
            </div>
            <section className='mb-4'>
              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#3b5998' }}
                href='https://www.facebook.com/MalayaleeSamajamAUH/'
                role='button'
              >
                <MDBIcon fab icon='facebook-f' />
              </a>

              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#55acee' }}
                href='/'
                role='button'
              >
                <MDBIcon fab icon='twitter' />
              </a>

              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#dd4b39' }}
                href='https://www.google.com/search?q=Abu+Dhabi+Malayalee+Samajam&rlz=1C1SQJL_enAE877AE877&tbm=lcl&sxsrf=ALiCzsZZUADGrCjmb3y5iFxe7kfAxGt7PQ%3A1655717907492&ei=E0CwYuvRHfiG4dUPhIWBuAw&oq=Abu+Dhabi+Malayalee+Samajam&gs_l=psy-ab.12...0.0.0.8350.0.0.0.0.0.0.0.0..0.0....0...1c..64.psy-ab..0.0.0....0.rh_WnVn_v5I#rlfi=hd:;si:17508858421805708102,l,ChtBYnUgRGhhYmkgTWFsYXlhbGVlIFNhbWFqYW1I4OWUj82CgIAIWi0QAhADGAAYARgCGAMiG2FidSBkaGFiaSBtYWxheWFsZWUgc2FtYWphbSoCCAKSARxzb2NpYWxfc2VydmljZXNfb3JnYW5pemF0aW9u;mv:[[24.350298477319022,54.514183050858044],[24.349938522680965,54.513787949141964]]'
                role='button'
              >
                <MDBIcon fab icon='google' />
              </a>
              <a
                className='btn btn-primary btn-floating m-1'
                style={{ backgroundColor: '#ac2bac' }}
                href='/'
                role='button'
              >
                <MDBIcon fab icon='instagram' />
              </a>

              
            </section>
          </div>
          {/* <div className="col-lg-4">               hide until the backend functionality is done
            <form action='' className='mt-5'>
              <MDBRow className='d-flex justify-content-center'>
                <MDBCol size='auto' className='mb-4 mb-md-0'>
                  <p className='footer-signup pt-2'>
                    <strong>Sign up for our newsletter</strong>
                  </p><br />
                  <div class="custom-search">
                  <input style={{width:"100%"}} required type="email" class="custom-search-input" placeholder="Enter your email" />
                  <button class="custom-search-botton" type="submit">Subscribe</button>

                
                </div>
             
                </MDBCol>

                
              </MDBRow>
            
            </form>
          </div> */}
          <div className="col-lg-5 iframe" style={{width:"35%", border:"10px solid gray", padding:"0"} } > {/*style={{height: mapHeight+'px', width:mapHeight*1.5+"px"}} */}
            <iframe
              title='mapMarker'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3634.896579431055!2d54.51179811537016!3d24.35009497151797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e40b6e1bedc4f%3A0xf2fbf5f7fe55f346!2sAbu%20Dhabi%20Malayalee%20Samajam!5e0!3m2!1sen!2sae!4v1655274559763!5m2!1sen!2sae'
              width='100%'
              height='100%'
              // height = {mapHeight+'px'}
              // width = {mapHeight*1.65 + 'px'}
              frameBorder='0'
              allowFullScreen
            />
          </div>
        </div>

      </div>


    </MDBFooter>


  );






}